import { useState, useEffect } from "react";
import "./../App.css";

import moment from 'moment'

import axios from "axios";
import { useOutletContext } from "react-router-dom";

import { flash } from "./flash";

const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config

export let Directory = () => {
    const outlet = useOutletContext() as any;
    const selectedSite = outlet.selectedSite;
    const sites = outlet.sites;
    const organizationName = outlet.organizationName;

    const [childFiles, setChildFiles] = useState([] as any[]);
    const [showEventDelete, setShowEventDelete] = useState(false);

    const [showListModel, setListShowModel] = useState(false);
    const [showFileModel, setFileShowModel] = useState(false);

    const [previewLink, setPreviewLink] = useState("");
    const [downloadLink, setDownloadLink] = useState("");

    const [versions, setVersions] = useState([] as any[]);
    const [events, setEvents] = useState([] as any[]);

    const [docTree, setDocTree] = useState([] as any[]);
    const [docTreeId, setDocTreeId] = useState([] as any[]);
    const [docTreeLoading, setDocTreeLoading] = useState(false);

    const [isVersionSelected, setIsVersionSelected] = useState(true);
    const [showFileModelConfirm, setShowFileModelConfirm] = useState(false);
    const [deleteEventEmail, setDeleteEventEmail] = useState("");

    const [restoreItemValue, setRestoreItemValue] = useState("");
    const [showRestoreItemModelConfirm, setShowRestoreItemModelConfirm] = useState(false);

    const [files, setFiles] = useState([] as any[]);
    const [query, setQuery] = useState("");

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');

    let restoreItemCancel = () => {
        setShowRestoreItemModelConfirm(false);
    }
    let restoreItemConfirm = () => {
        setShowRestoreItemModelConfirm(true);
    }
    let deleteEventCancel = () => {
        setShowEventDelete(false);
    }
    let deleteEventShow = (email: any) => {
        setDeleteEventEmail(email);
        setShowEventDelete(true);
    }

    const [dataFileModel, setDataFileModel] = useState({
        id: null,
        name: null,
        webUrl: null,
        file: { mimeType: null },
        folder: { childCount: null },
        createdDateTime: null,
        size: null,
        lastModifiedBy: { user: { displayName: null } },
        displayName: null,
    });
    const [dataListModel, setDataListModel] = useState({
        id: null,
        mailNickname: null,
        contentType: { name: null },
        lastModifiedDateTime: null,
        webUrl: null,
        name: null,
        lastModifiedBy: { user: { displayName: null } },
        description: null,
        displayName: null,
    });

    const onClickListHideModel = () => { setListShowModel(false); setDocTree([]); setDocTreeId([]); }
    const onClickFileHideModel = () => { setFileShowModel(false); setPreviewLink(""); onClickListHideModel(); }
    const onClickFileShowModel = (d: any, f: boolean, ff: boolean) => {
        if (!f) { setIsVersionSelected(true); }
        setDocTree([]);
        setDocTreeId([]);
        setDataListModel(d);
        setDataFileModel(d);
        callPreviewItemAPI(d);
        callDownloadItemAPI(d, ff);
        callVersionsItemAPI(d);
        callEventsItemAPI(d);
        callMeParentAPI(d, f);
        callItemAPI(d);
        setTimeout(() => { localStorage.setItem('openDirectory', null!); }, 3000);
    }

    let deleteItemCancel = () => {
        setShowFileModelConfirm(false);
    }
    let deleteItemConfirm = () => {
        setShowFileModelConfirm(true);
    }
    let clickVersion = () => {
        setIsVersionSelected(true);
    }
    let clickEvents = () => {
        setIsVersionSelected(false);
    }

    let createNewFolderAPI = () => {
        const token = localStorage.getItem("token") as string;

        const _id = document.getElementById("SelectedItemId") as HTMLInputElement | null;
        const _namee = document.getElementById("NewFolderTXT") as HTMLInputElement | null;
        const _select = document.getElementById("NewFolderSelect") as HTMLInputElement | null;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        let parentV = docTreeId.length > 0 ? docTreeId[docTreeId.length - 1] : null;
        if ((!parentV || (parentV && !parentV.folder)) && docTreeId.length - 2 >= 0) {
            parentV = docTreeId[docTreeId.length - 2];
        }

        let url = "";
        let obj = {};

        if (_select!.value === "Folder") {
            url = domainurl + "/createFolder";
            obj = { site: selectedSite.id, value: _namee!.value, parent: (parentV && parentV.id && parentV.id !== 'root') ? parentV.id : undefined }
        } else {
            url = domainurl + "/createDocument";
            obj = { site: selectedSite.id, doc: _select!.value, value: (((docTree.length > 0 && (_id && _id!.value !== 'root')) ? (docTreeId.filter((d) => { return d.folder && d.id !== 'root' }).map((d) => { return d.name }).join('/') + '/') : '')) + _namee!.value, parent: (_id && _id!.value !== 'root') ? _id!.value : undefined }
        }

        instance
            .post(url, obj)
            .then((response) => {
                /* Start Check for Errors */
                let ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                onClickFileShowModel(dataListModel, true, false);

                _namee!.value = "";
            })
            .catch((error) => {
            })
            .finally(() => { });
    }
    let callDownloadItemAPI = (d: any, ff: boolean) => {
        if (d && d.folder) {
            return;
        }
        const token = localStorage.getItem("token") as string;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        instance
            .post(domainurl + "/downloadItem", { site: selectedSite.id, value: d!.id, })
            .then((response: any) => {
                /* Start Check for Errors */
                let token = localStorage.getItem("token");
                if (!token) {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                setDownloadLink(response.data);
                localStorage.setItem('openDirectory', null!);

                if (ff) {
                    setFileShowModel(true);
                };
                setListShowModel(false);
            })
            .catch((error) => {
            })
            .finally(() => { });
    }
    let callEventsItemAPI = (d: any) => {
        const token = localStorage.getItem("token") as string;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        instance
            .post(domainurl + "/events", { site: selectedSite.id, value: d!.id })
            .then((response: any) => {
                /* Start Check for Errors */
                let token = localStorage.getItem("token");
                if (!token) {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                setEvents(response.data);
            })
            .catch((error) => {
            })
            .finally(() => { });
    }
    let callMeAPI = (token: string, root: boolean) => {
        setTimeout(() => {
            /*if (!(selectedSite && selectedSite.id && selectedSite.id !== 'null')) {
              return;
            }*/

            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/meF", { site: selectedSite.id })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */

                    const f: any[] = response.data.files;
                    setFiles([...f]);

                    setLoading2(false);
                })
                .catch((error) => { })
                .finally(() => { });
        }, 0)
    }
    let callMeParentAPI = (data: any, flag: boolean) => {
        setTimeout(() => {
            if (!(selectedSite && selectedSite.id && selectedSite.id !== 'null')) {
                return;
            }

            const token = localStorage.getItem("token") as string;
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/meF", { site: selectedSite.id, parent: data.id })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */

                    if (data.id === 'root') {
                        setDocTreeId([data]);
                        setDocTree([data.name]);
                    } else if (flag) {
                        setDocTreeId([...docTreeId]);
                        setDocTree([...docTree]);
                    } else {
                        setDocTreeId([...docTreeId, data]);
                        setDocTree([...docTree, data.name]);
                    }
                    setDocTreeLoading(false);

                    const f: any[] = response.data.files;
                    setChildFiles([...f]);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }
    let callItemAPI = (d: any) => {
        setTimeout(() => {
            if (!(selectedSite && selectedSite.id && selectedSite.id !== 'null')) {
                return;
            }

            const token = localStorage.getItem("token") as string;
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/fetchItem", { site: selectedSite.id, value: d.id })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */

                    if (d.id === 'root') {
                        setDataFileModel(d);
                    } else {
                        setDataFileModel(response.data);
                    }
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }
    let callPreviewItemAPI = (d: any) => {
        if (d && d.folder) {
            return;
        }
        const token = localStorage.getItem("token") as string;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        instance
            .post(domainurl + "/previewItem", { site: selectedSite.id, value: d!.id, })
            .then((response: any) => {
                /* Start Check for Errors */
                let token = localStorage.getItem("token");
                if (!token) {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                setPreviewLink(response.data);
            })
            .catch((error) => {
            })
            .finally(() => { });
    }
    let callVersionsItemAPI = (d: any) => {
        if (d && d.folder) {
            return;
        }
        const token = localStorage.getItem("token") as string;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        instance
            .post(domainurl + "/fetchVersions", { site: selectedSite.id, value: d!.id, })
            .then((response: any) => {
                /* Start Check for Errors */
                let token = localStorage.getItem("token");
                if (!token) {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                setVersions(response.data.value);
            })
            .catch((error) => {
            })
            .finally(() => { });
    }
    let subscribeToAPI = () => {
        const token = localStorage.getItem("token") as string;
        const _id = document.getElementById("SelectedItemId") as HTMLInputElement | null;
        const _idEvents = document.getElementById("events") as HTMLInputElement | null;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        setShowRestoreItemModelConfirm(false);

        instance
            .post(domainurl + "/createEvent", { site: selectedSite.id, documentId: _id!.value, email: _idEvents!.value })
            .then((response) => {
                /* Start Check for Errors */
                let ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                onClickFileShowModel(dataFileModel, true, true);
                _idEvents!.value = "";
            })
            .catch((error) => {
            })
            .finally(() => { });
    }
    let deleteEventAPI = (email: any) => {
        const token = localStorage.getItem("token") as string;
        const _id = document.getElementById("SelectedItemId") as HTMLInputElement | null;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        setShowEventDelete(false);

        instance
            .post(domainurl + "/deleteEvent", { site: selectedSite.id, documentId: _id!.value, email: email })
            .then((response) => {
                /* Start Check for Errors */
                let ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                onClickFileShowModel(dataFileModel, true, true);
            })
            .catch((error) => {
            })
            .finally(() => { });
    }
    let restoreItemAPI = (rid: string) => {
        const token = localStorage.getItem("token") as string;
        const _id = document.getElementById("SelectedItemId") as HTMLInputElement | null;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        setShowRestoreItemModelConfirm(false);

        instance
            .post(domainurl + "/restoreItem", { site: selectedSite.id, value: _id!.value, versionId: rid })
            .then((response) => {
                /* Start Check for Errors */
                let ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                onClickFileShowModel(dataFileModel, true, true);
            })
            .catch((error) => {
            })
            .finally(() => { });
    }
    let deleteItemAPI = () => {
        const token = localStorage.getItem("token") as string;
        const _id = document.getElementById("SelectedItemId") as HTMLInputElement | null;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        setShowFileModelConfirm(false);

        instance
            .post(domainurl + "/deleteItem", { site: selectedSite.id, value: _id!.value, })
            .then((response) => {
                /* Start Check for Errors */
                let ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                setDocTreeId([] as any[]);
                setTimeout(() => {
                    onClickFileShowModel({ 'id': 'root', 'webUrl': selectedSite.webUrl, 'name': 'Root', 'folder': { 'childCount': 0 }, 'size': 0, 'lastModifiedBy': { 'user': { 'displayName': 'TJMicro' } } }, false, true);
                }, 10);

                setFileShowModel(false);
                setListShowModel(false);
                onClickFileHideModel();
            })
            .catch((error) => {
            })
            .finally(() => { });
    }

    let refresh = () => {
        onClickFileShowModel(dataFileModel, true, true);
    }

    useEffect(() => {
        let openDirectory = localStorage.getItem('openDirectory');
        if (!(openDirectory && openDirectory !== 'null')) {
            onClickFileShowModel({ 'id': 'root', 'webUrl': selectedSite.webUrl, 'name': 'Root', 'folder': { 'childCount': 0 }, 'size': 0, 'lastModifiedBy': { 'user': { 'displayName': 'TJMicro' } } }, false, true);
        }
    }, [selectedSite]);

    if (!loading2) {
        let openDirectory = localStorage.getItem('openDirectory');
        if (openDirectory && openDirectory !== 'null') {
            openDirectory = JSON.parse(openDirectory);
            onClickFileShowModel(openDirectory, false, true)
        } else {
            onClickFileShowModel({ 'id': 'root', 'webUrl': selectedSite.webUrl, 'name': 'Root', 'folder': { 'childCount': 0 }, 'size': 0, 'lastModifiedBy': { 'user': { 'displayName': 'TJMicro' } } }, false, true);
        }

        setLoading(true);
        setLoading2(true);
    }

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            <div className="headerFolders">
                <span className="newFolder">
                    <input type="text" id="NewFolderTXT" placeholder="Name..." />
                    <select id="NewFolderSelect">
                        <option value="Folder">Folder</option>
                        <option value="Word">Word</option>
                        <option value="Excel">Excel</option>
                        <option value="PowerPoint">PowerPoint</option>
                        <option value="Text">Text</option>
                    </select>
                    <button id="NewFolderButton" onClick={createNewFolderAPI}>Create</button>
                </span>
                <div className="splash">File Structure</div>
                <div onClick={() => { refresh() }} className="refresh">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                        <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                        <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                    </svg>
                </div>
            </div>
            <div className="three three-file">
                <div className="">
                    <strong>{(loading2 === false) ? <div className="">
                        <svg className="circlespinner" viewBox="0 0 50 50">
                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                        </svg>
                    </div> : (files.length < 0) ?
                        <div>{
                            files.filter((ss: any) => {
                                if (query === '') {
                                    return ss;
                                } else if (ss.name.toLowerCase().includes(query.toLowerCase())) {
                                    return ss;
                                }
                            }).map((d, i) => (
                                <div className="item" onClick={() => onClickFileShowModel(d, false, true)} key={i}>
                                    <span>{d["name"]}</span>
                                    <strong> </strong>
                                    <span className="AlignRight AlignRightRep">{(d.file) ? moment(d["lastModifiedDateTime"]).format('l LT') : "Folder"}</span>
                                </div>
                            ))
                        }</div> : <span></span>}</strong>
                    <footer>File Structure</footer>
                </div>
            </div>
            {(showListModel || showFileModel || true) ?
                <div onClick={onClickFileHideModel} className="model3 right">
                    <span className="model-close">X</span>
                    <div onClick={(e) => { e.stopPropagation(); }} className="model-body">
                        <div className="model-body-header">
                            <span>{dataFileModel.name}</span>
                            <span onClick={() => { onClickFileShowModel(dataFileModel, true, true); }} className="model-body-refresh">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                                    <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                                    <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                                </svg>
                            </span>
                        </div>
                        <div className="model-body-content">
                            <div className="small-box small-boxx">
                                {(docTreeId.length > 0) ? docTreeId.map((d, i) => (
                                    <span key={i}>{
                                        (!docTreeLoading) ? <><span onClick={() => { if (docTreeId.length - 1 !== i && docTreeId.length - 1 !== i) { setDocTreeLoading(true); setDocTreeId([...docTreeId.splice(i, (docTreeId.length - i + 1))]); setDocTree([...docTree.splice(i, (docTree.length - i + 1))]); callMeParentAPI(d, false); setDataListModel(d); callEventsItemAPI(d); setDataFileModel(d); } }}>{d.name}</span><span>{(docTreeId.length - 1 !== i) ? <span> / </span> : <></>}</span></> : <span>...</span>
                                    }</span>
                                )) : <span>...</span>}
                            </div>
                            <div>
                                <input id="SelectedItemId" type="hidden" value={dataFileModel.id!} />
                                <div className="pad small-box small-boxx">
                                    <label>Subscribe to Events:</label> <input id="events" className="events" type="text" />
                                    <button onClick={subscribeToAPI} className="small">Subscribe</button>
                                </div>
                                <span className=""><input onChange={(event) => { setQuery(event.target.value); }} className="SearchBarSites DirectorySearch" type="text" placeholder="Search Directory ..." /></span>
                                {(!dataFileModel.folder) ?
                                    <div className="PreviewIFrame Structure small-box iframe"><iframe className="PreviewIFrameFull" src={previewLink} title="Preview"></iframe></div> :
                                    (childFiles.filter((ss: any) => {
                                        if (query === '') {
                                            return ss;
                                        } else if (ss.name.toLowerCase().includes(query.toLowerCase())) {
                                            return ss;
                                        }
                                    }).length > 0) ?
                                        <div className="PreviewIFrame Structure small-box"><div className="PreviewIFrame Structure small-box iiiiii">
                                            {(!docTreeLoading) ? childFiles.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if (ss.name.toLowerCase().includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).map((d, i) => (<div key={i} onClick={() => { setDocTreeLoading(true); if (d.folder) { callMeParentAPI(d, false); setDataListModel(d); callEventsItemAPI(d); setDataFileModel(d); } else { onClickFileShowModel(d, false, true); } }} className="item"><span>{d.name}</span> <span className="AlignRight AlignRightRep">{(d.file) ? moment(d["lastModifiedDateTime"]).format('l LT') : "Folder"}</span> <span className="AlignRight">{Number((d.size / 1000 / 1000).toFixed(3))} MB</span></div>)) : <></>}
                                        </div></div> : <div className="PreviewIFrame Structure Border small-box">No Items in Folder</div>}
                                <div className="small-box-container">
                                    <div className="small-box-t"><div className="small-box small-box-bot">{((dataFileModel.file) ? dataFileModel.file.mimeType : (dataFileModel.folder ? dataFileModel.folder.childCount : ''))}</div></div>
                                    <div className="small-box-t small-box-tt"><div className="small-box small-box-bot">{dataFileModel.size}</div></div>
                                    <div className="small-box-t"><div className="small-box small-box-bot">{(dataFileModel.lastModifiedBy && dataFileModel.lastModifiedBy.user) ? dataFileModel.lastModifiedBy.user.displayName : ""}</div></div>
                                    <div className={(!dataFileModel.folder) ? "clear" : "clear clearleft"}>
                                        <a className="website-url" target="_blank" rel="noreferrer" href={(selectedSite.id === 'onedrive') ? ("https://" + organizationName + "-my.sharepoint.com/") : dataFileModel.webUrl!}>WebSite URL</a>
                                        {dataFileModel.folder ? <></> : <a className="download-item" href={downloadLink}>Download File</a>}
                                        {dataFileModel.id === 'root' ? <></> : <button className="delete-item" onClick={deleteItemConfirm}>Delete Item</button>}
                                    </div>
                                </div>
                            </div>

                            {showFileModelConfirm ?
                                <div className="model6">
                                    <div className="modelConfirm">
                                        Are you sure you want to delete item?
                                        <button className="delete-item" onClick={deleteItemAPI}>Delete</button>
                                        <button className="ahref" onClick={deleteItemCancel}>Cancel</button>
                                    </div>
                                </div>
                                : <></>}
                        </div>
                        <div className="model-body-content-right">
                            <h2>{
                                (!dataFileModel.folder) ?
                                    <><span onClick={clickVersion} className={(isVersionSelected ? "menuItem selected" : "menuItem")}>Versions</span><span> | </span><span onClick={clickEvents} className={(!isVersionSelected ? "menuItem selected" : "menuItem")}>Events</span></>
                                    :
                                    <><span onClick={clickEvents} className={(!isVersionSelected ? "menuItem selected" : "menuItem")}>Events</span></>
                            }</h2>
                            {(isVersionSelected && !dataFileModel.folder) ?
                                <div className="version-history-container">{(versions ? versions : []).map((d, i) => (
                                    <div key={i} className="version-history-item">
                                        <div>
                                            <strong>Date: {moment(d.lastModifiedDateTime).format('l LT')}</strong>
                                        </div>
                                        <span className="version-last">Last Modified by: </span>
                                        <div>
                                            <strong>{d.lastModifiedBy.user.displayName}</strong>
                                        </div>
                                        {(i > 0) ? <div>
                                            <button className="version-history-button" onClick={() => { restoreItemConfirm(); setRestoreItemValue(d.id); }}>Restore</button>
                                        </div> : <button disabled className="version-history-button disabled">Current Version</button>}
                                    </div>
                                ))}
                                    {showRestoreItemModelConfirm ?
                                        <div className="model6">
                                            <div className="modelConfirm">
                                                <div>Are you sure you want to restore item?</div>
                                                <button onClick={() => { restoreItemAPI(restoreItemValue) }}>Restore</button>
                                                <button className="ahref" onClick={restoreItemCancel}>Cancel</button>
                                            </div>
                                        </div>
                                        : <></>}
                                </div>
                                : <div className="version-history-container">
                                    {(events.length > 0) ? events.map((e, i) => (
                                        <div key={i} className="version-history-item full">{e.email}<span onClick={() => deleteEventShow(e.email)}>X</span></div>
                                    )) : <div className="version-history-item full empty">No Events</div>}
                                    {showEventDelete ?
                                        <div className="model6">
                                            <div className="modelConfirm">
                                                <div>Are you sure you want to delete event?</div>
                                                <button onClick={() => { deleteEventAPI(deleteEventEmail) }}>Delete</button>
                                                <button className="ahref" onClick={deleteEventCancel}>Cancel</button>
                                            </div>
                                        </div>
                                        : <></>}
                                </div>}
                        </div>
                    </div>
                </div> : <></>}
        </>
    )
}