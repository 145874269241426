import { useState, useEffect } from "react";
import "./../App.css";
import axios from "axios";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import { flash } from "./flash";
const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config
export let TJMicroAdmin = () => {
    const token = localStorage.getItem("admintoken") as string;
    const [ready, setReady] = useState(false);
    const [organizations, setOrganizations] = useState([] as any[]);
    const [permissions, setPermissions] = useState({
        "adminuser": null, "showAuthToken": null, "showGoogleDriveAPI": null, "showSFTPCredentials": null, "showNewOrganization": null, "showUsers": null, "showNewUsers": null
    });
    const [users, setUsers] = useState([] as any[]);
    const [adminUsers, setAdminUsers] = useState([] as any[]);
    const [expiry, setExpiry] = useState(0);
    const [backup, setBackup] = useState(0);
    const [driveDeltas, setDriveDeltas] = useState(0);
    const [itemDifferentials, setItemDifferentials] = useState(0);
    const [emailReports, setEmailReports] = useState(0);
    const [mongoBackup, setMongoBackup] = useState(0);
    const [accessTokens, setAccessTokens] = useState(0);
    const [emailMessages, setEmailMessages] = useState(0);
    const [org, setOrg] = useState('');
    const [SFTPHost, setSFTPHost] = useState('');
    const [SFTPUser, setSFTPUser] = useState('');
    const [SFTPPass, setSFTPPass] = useState('');
    const [SFTPCap, setSFTPCap] = useState(0);
    const [userPermission, setUserPermission] = useState(-1);
    const [Credentials, setCredentials] = useState('');
    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');
    let callUsersAPI = () => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/adminusers", {})
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        setLoading2(false);
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    setUsers(response.data);
                    setReady(true);
                    setLoading2(false);
                })
                .catch((error) => {
                    setReady(false);
                    setLoading2(false);
                })
                .finally(() => { });
        }, 0)
    }
    let callLocalUsersAPI = () => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/adminLocalUsers", {})
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        //setLoading2(false);
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    setAdminUsers(response.data);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }
    let callSettingsAPI = (orgid: string) => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/adminsettings", { org: orgid })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        setLoading2(false);
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    setExpiry(response.data.expiry);
                    setBackup(response.data.backup);
                    setDriveDeltas(response.data.driveDeltas);
                    setItemDifferentials(response.data.itemDifferentials);
                    setEmailReports(response.data.emailReports);
                    setMongoBackup(response.data.mongoBackup);
                    setAccessTokens(response.data.accessTokens);
                    setEmailMessages(response.data.emailMessages);
                    setReady(true);
                    setLoading2(false);
                })
                .catch((error) => {
                    setReady(false);
                    setLoading2(false);
                })
                .finally(() => { });
        }, 0)
    }
    let callSFTPAPI = (orgid: string) => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/adminSFTP", { org: orgid })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        setLoading2(false);
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    setSFTPHost(response.data.host);
                    setSFTPUser(response.data.user);
                    setSFTPPass(response.data.pass);
                    setSFTPCap(response.data.capacity);
                    setReady(true);
                    setLoading2(false);
                })
                .catch((error) => {
                    setReady(false);
                    setLoading2(false);
                })
                .finally(() => { });
        }, 0)
    }
    let callGDriveAPI = (orgid: string) => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/adminGDRIVE", { org: orgid })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        setLoading2(false);
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    setCredentials(response.data);
                    setReady(true);
                    setLoading2(false);
                })
                .catch((error) => {
                    setReady(false);
                    setLoading2(false);
                })
                .finally(() => { });
        }, 0)
    }
    let callOrganizationsAPI = (controller: AbortController) => {
        setTimeout(() => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/adminorganizations", {})
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        setLoading2(false);
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    setOrganizations(response.data.organizations);
                    setPermissions(response.data.permissions);
                    if (response.data.organizations.length > 0) {
                        setOrg(response.data.organizations[0]._id);
                        load(response.data.organizations[0]._id);
                    }
                    callLocalUsersAPI();
                    setReady(true);
                    setLoading2(false);
                })
                .catch((error) => {
                    setReady(false);
                    setLoading2(false);
                })
                .finally(() => { });
        }, 0)
    }
    let callCreateOrganizationAPI = () => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            const name = document.getElementById("adminname") as HTMLInputElement | null;
            const tenentid = document.getElementById("admintenentid") as HTMLInputElement | null;
            const clientid = document.getElementById("adminclientid") as HTMLInputElement | null;
            const clientsecret = document.getElementById("adminclientsecret") as HTMLInputElement | null;
            const sharepointclientid = document.getElementById("adminsharepointclientid") as HTMLInputElement | null;
            const sharepointclientsecret = document.getElementById("adminsharepointclientsecret") as HTMLInputElement | null;
            if (name === null || tenentid === null || clientid === null || clientsecret === null || sharepointclientid === null || sharepointclientsecret === null) {
                return;
            }
            instance
                .post(domainurl + "/createAdminOrganizations", {
                    name: name.value,
                    tenantid: tenentid.value,
                    clientid: clientid.value,
                    clientsecret: clientsecret.value,
                    sharepointclientid: sharepointclientid.value,
                    sharepointclientsecret: sharepointclientsecret.value,
                })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    const controller = new AbortController();
                    callOrganizationsAPI(controller);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }
    let callLoginAPI = () => {
        const username = document.getElementById("adminusername") as HTMLInputElement | null;
        const password = document.getElementById("adminpassword") as HTMLInputElement | null;
        if (username === null || password === null) {
            return;
        }
        const instance = axios.create({
            withCredentials: true
        });
        instance
            .post(domainurl + "/adminlogin",
                {
                    username: username.value,
                    password: password.value,
                })
            .then((response) => {
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                localStorage.setItem("admintoken", response.data)
                setReady(true);
                const controller = new AbortController();
                callOrganizationsAPI(controller);
            })
            .catch((error) => {
            })
            .finally(() => { });
    }
    let authTokenSettingsSave = () => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            const adminauthexpiry = document.getElementById("adminauthexpiry") as HTMLInputElement | null;
            const adminbackup = document.getElementById("adminbackup") as HTMLInputElement | null;
            const adminbackup1 = document.getElementById("adminbackup1") as HTMLInputElement | null;
            const adminbackup2 = document.getElementById("adminbackup2") as HTMLInputElement | null;
            const adminbackup3 = document.getElementById("adminbackup3") as HTMLInputElement | null;
            const adminbackup4 = document.getElementById("adminbackup4") as HTMLInputElement | null;
            const adminbackup5 = document.getElementById("adminbackup5") as HTMLInputElement | null;
            const adminbackup6 = document.getElementById("adminbackup6") as HTMLInputElement | null;
            if (adminauthexpiry === null || adminbackup === null
                || adminbackup1 === null || adminbackup2 === null
                || adminbackup3 === null || adminbackup4 === null
                || adminbackup5 === null || adminbackup6 === null) {
                return;
            }
            instance
                .post(domainurl + "/adminAuthExpiry", {
                    org: org,
                    expiry: adminauthexpiry.value,
                    backup: adminbackup.value,
                    driveDeltas: adminbackup1.value,
                    itemDifferentials: adminbackup2.value,
                    emailReports: adminbackup3.value,
                    mongoBackup: adminbackup4.value,
                    accessTokens: adminbackup5.value,
                    emailMessages: adminbackup6.value,
                })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                })
                .catch((error) => { })
                .finally(() => { });
        }, 0)
    }
    let sftpSettingsSave = () => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            const adminsftphost = document.getElementById("adminsftphost") as HTMLInputElement | null;
            const adminsftpuser = document.getElementById("adminsftpuser") as HTMLInputElement | null;
            const adminsftppassword = document.getElementById("adminsftppassword") as HTMLInputElement | null;
            const adminsftpcap = document.getElementById("adminsftpcap") as HTMLInputElement | null;

            if (adminsftphost === null || adminsftpuser === null || adminsftppassword === null || adminsftpcap === null) {
                return;
            }
            instance
                .post(domainurl + "/adminAuthSFTP", {
                    org: org,
                    host: adminsftphost.value,
                    user: adminsftpuser.value,
                    pass: adminsftppassword.value,
                    cap: adminsftpcap.value,
                })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                })
                .catch((error) => { })
                .finally(() => { });
        }, 0)
    }
    let callCreateAdminUserAPI = () => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            const email = document.getElementById("adminuseremail") as HTMLInputElement | null;
            const username = document.getElementById("adminuserusername") as HTMLInputElement | null;
            const password = document.getElementById("adminuserpassword") as HTMLInputElement | null;
            if (email === null || username === null || password === null) {
                return;
            }
            instance
                .post(domainurl + "/adminCreateNewUser", {
                    email: email.value,
                    username: username.value,
                    password: password.value,
                })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    const controller = new AbortController();
                    callOrganizationsAPI(controller);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }
    let saveAdminUserPermissions = (i: any) => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            let _adminUsers = [adminUsers[i]];
            instance
                .post(domainurl + "/adminUsersPermission", {
                    adminuser: _adminUsers.map((x) => {
                        return {
                            "adminuser": x.permissions.adminuser,
                            "showauthtoken": x.permissions.showAuthToken,
                            "showgoogledriveapi": x.permissions.showGoogleDriveAPI,
                            "showsftpcredentials": x.permissions.showSFTPCredentials,
                            "showneworganization": x.permissions.showNewOrganization,
                            "showusers": x.permissions.showUsers,
                            "shownewusers": x.permissions.showNewUsers,
                        }
                    })
                })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    const controller = new AbortController();
                    callOrganizationsAPI(controller);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }
    let callConnectToDriveAPI = () => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            const admincredentials = document.getElementById("admincredentials") as HTMLInputElement | null;

            if (admincredentials === null) {
                return;
            }

            instance
                .post(domainurl + "/adminGoogleDriveAPI", {
                    credentials: admincredentials.value,
                    org: org
                })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("admintoken");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */

                    window.location.href = response.data;
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }
    let callDeleteUser = (o: any) => {
        const instance = axios.create({
            withCredentials: true
        });

        instance
            .post(domainurl + "/adminDeleteUser", { value: o._id })
            .then((response) => {
                /* Start Check for Errors */
                let ttoken = localStorage.getItem("admintoken");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                callUsersAPI();
            })
            .catch((error) => { })
    }
    let load = (orgid: string) => {
        callSettingsAPI(orgid);
        callSFTPAPI(orgid);
        callGDriveAPI(orgid);
        callUsersAPI();
    }
    let logout = () => {
        setReady(false);
        const instance = axios.create({
            withCredentials: true
        });

        instance
            .post(domainurl + "/adminlogout", {})
            .then((response) => {
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                localStorage.setItem("admintoken", null!);
                setReady(false);
            })
            .catch((error) => { })
    }

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        setLoading2(true);
        callOrganizationsAPI(controller);
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            {(!loading2) ? (!ready) ?
                (<div className="adminFS">
                    <div><input id="adminusername" type="text" placeholder="username" /></div>
                    <div><input id="adminpassword" type="password" placeholder="password" /></div>
                    <div><button onClick={callLoginAPI}>Login</button></div>
                </div>)
                :
                <div className="adminFS">
                    <h2>Organizations</h2>
                    <div>{(organizations.length > 0) ? organizations.map((o) => (
                        <span>{o.name}</span>
                    )) : <span>No Organizations</span>}</div>
                    <div className={(permissions.showUsers) ? "organization-wrapper" : "organization-wrapper hiudden"}>
                        <h4>Users</h4>
                        <div>{(users.length > 0) ? users.map((o) => (
                            <span>{o.email} <span className="link" onClick={() => { callDeleteUser(o) }}>X</span></span>
                        )) : <span>No Users</span>}</div>
                    </div>
                    <div className={(permissions.showNewOrganization) ? "organization-wrapper left" : "organization-wrapper left hiudden"}>
                        <h3>New Organization</h3>
                        <div className="organization-container">
                            <input id="adminname" type="text" placeholder="Name" /><span> Name</span><br />
                            <input id="admintenentid" type="text" placeholder="Tenent Id" /><span> Tenent Id</span><br />
                            <input id="adminclientid" type="text" placeholder="Client Id" /><span> Client Id</span><br />
                            <input id="adminclientsecret" type="text" placeholder="Client Secret" /><span> Client Secret</span><br />
                            <input id="adminsharepointclientid" type="text" placeholder="Sharepoint Client Id" /><span> Sharepoint Client Id</span><br />
                            <input id="adminsharepointclientsecret" type="text" placeholder="Sharepoint Client Secret" /><span> Sharepoint Client Secret</span><br />
                        </div>
                        <button onClick={callCreateOrganizationAPI}>Create Organization</button>
                    </div>
                    <div className={(permissions.showNewUsers) ? "organization-wrapper left" : "organization-wrapper left hiudden"}>
                        <h3>New Admin User</h3>
                        <div className="organization-container">
                            <input id="adminuseremail" type="text" placeholder="Email" /><span> Email</span><br />
                            <input id="adminuserusername" type="text" placeholder="Username" /><span> Username</span><br />
                            <input id="adminuserpassword" type="text" placeholder="Password" /><span> Password</span><br />
                        </div>
                        <button onClick={callCreateAdminUserAPI}>Create Admin User</button>
                    </div>
                    <div className="section scrolly">
                        <div>
                            <h3>Select an Organization:</h3>
                            <span>
                                <select onChange={(e) => { setOrg(e.target.value); load(e.target.value); }} value={org} className="organizationdd">
                                    {(organizations.length > 0) ? organizations.map((o) => (
                                        <option value={o._id}>{o.name}</option>
                                    )) : <option>No Organizations</option>}
                                </select>
                            </span>
                        </div>
                        <div className={(permissions.showAuthToken) ? "organization-wrapper" : "organization-wrapper hiudden"}>
                            <h3>Authentication Token Settings</h3>
                            <h4>Auth Token Expiry</h4>
                            <div className="organization-container">
                                <input id="adminauthexpiry" type="text" value={expiry} onChange={(e) => { setExpiry((e.target.value !== '') ? parseInt(e.target.value) : 0) }} placeholder="Expiry (minutes)" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} /><span> Expiry (minutes)</span><br />
                            </div>
                            <h4>Backup Schedule</h4>
                            <div className="organization-container">
                                <input id="adminbackup" type="text" value={backup} onChange={(e) => { setBackup((e.target.value !== '') ? parseInt(e.target.value) : 0) }} placeholder="Every (minutes)" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} /><span> Every (minutes)</span><br />
                            </div>
                            <h4>Drive Deltas Schedule</h4>
                            <div className="organization-container">
                                <input id="adminbackup1" type="text" value={driveDeltas} onChange={(e) => { setDriveDeltas((e.target.value !== '') ? parseInt(e.target.value) : 0) }} placeholder="Every (minutes)" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} /><span> Every (minutes)</span><br />
                            </div>
                            <h4>Item Differentials Schedule</h4>
                            <div className="organization-container">
                                <input id="adminbackup2" type="text" value={itemDifferentials} onChange={(e) => { setItemDifferentials((e.target.value !== '') ? parseInt(e.target.value) : 0) }} placeholder="Every (minutes)" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} /><span> Every (minutes)</span><br />
                            </div>
                            <h4>Email Reports Schedule</h4>
                            <div className="organization-container">
                                <input id="adminbackup3" type="text" value={emailReports} onChange={(e) => { setEmailReports((e.target.value !== '') ? parseInt(e.target.value) : 0) }} placeholder="Every (minutes)" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} /><span> Every (minutes)</span><br />
                            </div>
                            <h4>Mongo Backup Schedule</h4>
                            <div className="organization-container">
                                <input id="adminbackup4" type="text" value={mongoBackup} onChange={(e) => { setMongoBackup((e.target.value !== '') ? parseInt(e.target.value) : 0) }} placeholder="Every (minutes)" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} /><span> Every (minutes)</span><br />
                            </div>
                            <h4>Access Tokens Schedule</h4>
                            <div className="organization-container">
                                <input id="adminbackup5" type="text" value={accessTokens} onChange={(e) => { setAccessTokens((e.target.value !== '') ? parseInt(e.target.value) : 0) }} placeholder="Every (minutes)" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} /><span> Every (minutes)</span><br />
                            </div>
                            <h4>Email Messages Schedule</h4>
                            <div className="organization-container">
                                <input id="adminbackup6" type="text" value={emailMessages} onChange={(e) => { setEmailMessages((e.target.value !== '') ? parseInt(e.target.value) : 0) }} placeholder="Every (minutes)" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} /><span> Every (minutes)</span><br />
                            </div>
                            <button onClick={authTokenSettingsSave}>Save</button>
                        </div>
                        <div className={(permissions.showGoogleDriveAPI) ? "organization-wrapper" : "organization-wrapper hiudden"}>
                            <h3>Google Drive API</h3>
                            <div className="organization-container">
                                <input id="admincredentials" type="text" value={Credentials} onChange={(e) => { setCredentials(e.target.value) }} placeholder="Credentials.json" /><span> Credentials</span><br />
                                <button onClick={callConnectToDriveAPI}>Connect to Google Drive</button>
                            </div>
                        </div>
                        <div className={(permissions.showSFTPCredentials) ? "organization-wrapper" : "organization-wrapper hiudden"}>
                            <h3>SFTP Credentials</h3>
                            <div className="organization-container">
                                <input id="adminsftphost" type="text" value={SFTPHost} onChange={(e) => { setSFTPHost(e.target.value) }} placeholder="0.0.0.0" /><span> Host</span><br />
                                <input id="adminsftpuser" type="text" value={SFTPUser} onChange={(e) => { setSFTPUser(e.target.value) }} placeholder="admin" /><span> User</span><br />
                                <input id="adminsftppassword" type="password" value={SFTPPass} onChange={(e) => { setSFTPPass(e.target.value) }} placeholder="password" /><span> Password</span><br />
                                <input id="adminsftpcap" type="text" value={SFTPCap} onChange={(e) => { setSFTPCap((e.target.value !== '') ? parseInt(e.target.value) : 0) }} placeholder="Capacity in GB's" /><span> Capacity GB's.</span><br />
                            </div>
                            <button onClick={sftpSettingsSave}>Save</button>
                        </div>
                        <div className={(permissions.showNewUsers) ? "organization-wrapper" : "organization-wrapper hiudden"}>
                            <h4>Admin User Permissions</h4>
                            <div className="organization-wrapper-cont">{(adminUsers.length > 0) ? adminUsers.map((o, i) => (
                                <div key={i}>
                                    <div onClick={() => { if (userPermission === i) { setUserPermission(-1); } else { setUserPermission(i); } }} className="organization-permissions">{o.user.email}</div>
                                    <div className={(userPermission === i) ? "organization-permissions-wrapper selected" : "organization-permissions-wrapper"}>
                                        <input type="hidden" id={"adminuser" + i} value={o.user._id} />
                                        <div className="organization-permissions-item">
                                            <label htmlFor={"showauthtoken" + i}>
                                                <span className="org-per-label">Auth Token</span>
                                                <input id={"showauthtoken" + i} className="ops" type="checkbox" checked={o.permissions.showAuthToken} onChange={() => { let uu = adminUsers; uu[i].permissions.showAuthToken = !uu[i].permissions.showAuthToken; setAdminUsers([...uu]); }} />
                                            </label>
                                        </div>
                                        <div className="organization-permissions-item">
                                            <label htmlFor={"showgoogledriveapi" + i}>
                                                <span className="org-per-label">Google Drive API</span>
                                                <input id={"showgoogledriveapi" + i} className="ops" type="checkbox" checked={o.permissions.showGoogleDriveAPI} onChange={() => { let uu = adminUsers; uu[i].permissions.showGoogleDriveAPI = !uu[i].permissions.showGoogleDriveAPI; setAdminUsers([...uu]); }} />
                                            </label>
                                        </div>
                                        <div className="organization-permissions-item">
                                            <label htmlFor={"showsftpcredentials" + i}>
                                                <span className="org-per-label">SFTP Credentials</span>
                                                <input id={"showsftpcredentials" + i} className="ops" type="checkbox" checked={o.permissions.showSFTPCredentials} onChange={() => { let uu = adminUsers; uu[i].permissions.showSFTPCredentials = !uu[i].permissions.showSFTPCredentials; setAdminUsers([...uu]); }} />
                                            </label>
                                        </div>
                                        <div className="organization-permissions-item">
                                            <label htmlFor={"showneworganization" + i}>
                                                <span className="org-per-label">New Organization</span>
                                                <input id={"showneworganization" + i} className="ops" type="checkbox" checked={o.permissions.showNewOrganization} onChange={() => { let uu = adminUsers; uu[i].permissions.showNewOrganization = !uu[i].permissions.showNewOrganization; setAdminUsers([...uu]); }} />
                                            </label>
                                        </div>
                                        <div className="organization-permissions-item">
                                            <label htmlFor={"showusers" + i}>
                                                <span className="org-per-label">Users</span>
                                                <input id={"showusers" + i} className="ops" type="checkbox" checked={o.permissions.showUsers} onChange={() => { let uu = adminUsers; uu[i].permissions.showUsers = !uu[i].permissions.showUsers; setAdminUsers([...uu]); }} />
                                            </label>
                                        </div>
                                        <div className="organization-permissions-item">
                                            <label htmlFor={"shownewusers" + i}>
                                                <span className="org-per-label">New Users</span>
                                                <input id={"shownewusers" + i} className="ops" type="checkbox" checked={o.permissions.showNewUsers} onChange={() => { let uu = adminUsers; uu[i].permissions.showNewUsers = !uu[i].permissions.showNewUsers; setAdminUsers([...uu]); }} />
                                            </label>
                                        </div>
                                        <div className="organization-permissions-item">
                                            <label htmlFor="events">
                                                <button onClick={() => { saveAdminUserPermissions(i) }} className="org-per-label">Save</button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )) : <span>No Admin Users</span>}</div>
                        </div>
                    </div>
                    <div className="organization-wrapper left">
                        <h3>Settings</h3>
                        <button onClick={logout}>Logout</button>
                    </div>
                </div> : <div className="adminFS"></div>
            }
        </>
    )
}

export let Admin = () => {
    const location = useLocation();

    /* Set Init Vars */

    const [init, setInit] = useState(false);
    const [ready, setReady] = useState(false);
    const [initLoading, setInitLoading] = useState(false);

    const [userPermission, setUserPermission] = useState(-1);
    const [users, setUsers] = useState([] as any[]);

    /* End Set Init Vars */

    /* Use Effect */

    useEffect(() => {
        if (initLoading) {
            document.title = "tjmicro";
        } else if (!ready) {
            document.title = "tjmicro - log in";
        } else {
            document.title = "tjmicro - portal";
        }
    }, [ready, initLoading]);

    /* End Use Effect */

    /* Axios States */

    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');

    /* End Axios States */

    let callUsersAPI = () => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/adminOrganizationUsers", {})
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        //setLoading2(false);
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */

                    setUsers(response.data);
                    setReady(true);
                    //setLoading2(false);
                })
                .catch((error) => {
                    setReady(false);
                    //setLoading2(false);
                })
                .finally(() => { });
        }, 0)
    }

    let savePermissions = (i: any) => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            let _users = users.map((x) => {
                return {
                    userid: x.user._id,
                    showHome: x.permissions.showHome,
                    showSites: x.permissions.showSites,
                    showDirectory: x.permissions.showDirectory,
                    showReport: x.permissions.showReport,
                    showRecycleBin: x.permissions.showRecycleBin,
                    showTeams: x.permissions.showTeams,
                    showBackups: x.permissions.showBackups,
                    showEvents: x.permissions.showEvents,
                    showPasswords: x.permissions.showPasswords,
                    showInventory: x.permissions.showInventory,
                }
            })

            instance
                .post(domainurl + "/adminOrganizationUsersPermission", {
                    oup: [_users[i]]
                })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        //setLoading2(false);
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */

                    //setLoading2(false);
                })
                .catch((error) => {
                    setReady(false);
                    //setLoading2(false);
                })
                .finally(() => { });
        }, 0)
    }

    let callSendInvite = () => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            const adminsendemail = document.getElementById("adminsendemail") as HTMLInputElement | null;
            if (adminsendemail === null) {
                return;
            }

            instance
                .post(domainurl + "/adminSendEmail", {
                    email: adminsendemail.value,
                })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                })
                .catch((error) => { })
                .finally(() => { });
        }, 0)
    }

    let callCheckAuth = () => {
        const instance = axios.create({
            withCredentials: true
        });

        instance
            .get(domainurl + "/checkAuthState", {})
            .then((response) => {
                if (response.data.organizationName !== undefined) {
                    localStorage.setItem("token", 'true');
                    setReady(true);
                    callUsersAPI();
                }
                setInitLoading(false);
            })
            .catch((error) => {
                setInitLoading(false);
            })
            .finally(() => { });
    }

    let token = localStorage.getItem("token");

    // TODO: Calls Twice Fix This
    if (!ready && !init && !initLoading) {
        setInit(true);
        setInitLoading(true!);
        setTimeout(() => {
            callCheckAuth();
        }, 1000);
    } else if (!(token && token !== null && token !== "null") &&
        (window.location.pathname !== '/') &&
        (window.location.pathname !== '/about')) {
        window.location.href = '/';
    }

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            {(ready) ?
                <div className="adminFS">
                    <h2>Administrator Panel</h2>
                    <div className="organization-wrapper">
                        <h4>Users</h4>
                        <div>{(users.length > 0) ? users.map((o, i) => (
                            <span key={i}>{o.user.email}</span>
                        )) : <span>No Users</span>}</div>
                    </div>
                    <div className="organization-wrapper">
                        <h3>Connection Status</h3>
                        <div className="organization-container">
                            You have connected to SharePoint API's Successfully.
                        </div>
                    </div>
                    <div className="organization-wrapper">
                        <h3>Invite User</h3>
                        <div className="organization-container">
                            <div><input id="adminsendemail" type="text" placeholder="Send Invite to Email Address" /></div>
                            <button onClick={() => { callSendInvite() }}>Send Invite</button>
                        </div>
                    </div>
                    <div className="organization-wrapper">
                        <h4>User Permissions</h4>
                        <div className="organization-wrapper-cont">{(users.length > 0) ? users.map((o, i) => (
                            <div key={i}>
                                <div onClick={() => { if (userPermission === i) { setUserPermission(-1); } else { setUserPermission(i); } }} className="organization-permissions">{o.user.email}</div>
                                <div className={(userPermission === i) ? "organization-permissions-wrapper selected" : "organization-permissions-wrapper"}>
                                    <div className="organization-permissions-item">
                                        <label htmlFor={"home" + i}>
                                            <span className="org-per-label">Home</span>
                                            <input id={"home" + i} className="ops" type="checkbox" checked={o.permissions.showHome} onChange={() => { let uu = users; uu[i].permissions.showHome = !uu[i].permissions.showHome; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor={"sites" + i}>
                                            <span className="org-per-label">Sites</span>
                                            <input id={"sites" + i} className="ops" type="checkbox" checked={o.permissions.showSites} onChange={() => { let uu = users; uu[i].permissions.showSites = !uu[i].permissions.showSites; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor={"directory" + i}>
                                            <span className="org-per-label">Directory</span>
                                            <input id={"directory" + i} className="ops" type="checkbox" checked={o.permissions.showDirectory} onChange={() => { let uu = users; uu[i].permissions.showDirectory = !uu[i].permissions.showDirectory; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor={"report" + i}>
                                            <span className="org-per-label">Report</span>
                                            <input id={"report" + i} className="ops" type="checkbox" checked={o.permissions.showReport} onChange={() => { let uu = users; uu[i].permissions.showReport = !uu[i].permissions.showReport; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor={"recyclebin" + i}>
                                            <span className="org-per-label">Recycle Bin</span>
                                            <input id={"recyclebin" + i} className="ops" type="checkbox" checked={o.permissions.showRecycleBin} onChange={() => { let uu = users; uu[i].permissions.showRecycleBin = !uu[i].permissions.showRecycleBin; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor={"teams" + i}>
                                            <span className="org-per-label">Teams</span>
                                            <input id={"teams" + i} className="ops" type="checkbox" checked={o.permissions.showTeams} onChange={() => { let uu = users; uu[i].permissions.showTeams = !uu[i].permissions.showTeams; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor={"backups" + i}>
                                            <span className="org-per-label">Backups</span>
                                            <input id={"backups" + i} className="ops" type="checkbox" checked={o.permissions.showBackups} onChange={() => { let uu = users; uu[i].permissions.showBackups = !uu[i].permissions.showBackups; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor="passwords">
                                            <span className="org-per-label">Passwords</span>
                                            <input id="passwords" className="ops" type="checkbox" checked={o.permissions.showPasswords} onChange={() => { let uu = users; uu[i].permissions.showPasswords = !uu[i].permissions.showPasswords; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor="inventory">
                                            <span className="org-per-label">Inventory</span>
                                            <input id="inventory" className="ops" type="checkbox" checked={o.permissions.showInventory} onChange={() => { let uu = users; uu[i].permissions.showInventory = !uu[i].permissions.showInventory; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor="events">
                                            <span className="org-per-label">Events</span>
                                            <input id="events" className="ops" type="checkbox" checked={o.permissions.showEvents} onChange={() => { let uu = users; uu[i].permissions.showEvents = !uu[i].permissions.showEvents; setUsers([...uu]); }} />
                                        </label>
                                    </div>
                                    <div className="organization-permissions-item">
                                        <label htmlFor="events">
                                            <button onClick={() => { savePermissions(i) }} className="org-per-label">Save</button>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )) : <span>No Users</span>}</div>
                    </div>
                    <div className="organization-wrapper">
                        <h3>Menu</h3>
                        <span>
                            <NavLink className={({ isActive }) => (!!matchPath(location.pathname, "/") && isActive ? 'active' : '')} to="/">
                                <span>Return </span>
                                <span className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
                                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                                </svg></span>
                            </NavLink>
                        </span>
                    </div>
                </div>
                : <div className="adminFS"></div>
            }
        </>
    )
}