import { useState, useEffect } from "react";
import "./../App.css";

import moment from 'moment'

import axios from "axios";
import { useOutletContext } from "react-router-dom";

import { flash } from "./flash";

const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config

export let Recycle = () => {
    const outlet = useOutletContext() as any;
    const selectedSite = outlet.selectedSite;
    const sites = outlet.sites;
    const organizationName = outlet.organizationName;

    const [deleted, setDeleted] = useState([] as any[]);
    const [perLimit, setPerLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [isOneDrive, setIsOneDrive] = useState(false);

    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');

    const [query, setQuery] = useState("");

    const [dataRecycleModel, setDataRecycleModel] = useState({
        AuthorEmail: null,
        AuthorName: null,
        DeletedByEmail: null,
        DeletedByName: null,
        DeletedDate: null,
        DeletedDateLocalFormatted: null,
        DirName: null,
        DirNamePath: { DecodedUrl: null },
        Id: null,
        id: null,
        ItemState: null,
        ItemType: null,
        LeafName: null,
        LeafNamePath: { DecodedUrl: null },
        Size: null,
        Title: null,
    });

    const [recycleShowModel, setRecycleShowModel] = useState(false);

    const onClickRecycleShowModel = (d: any) => {
        setDataRecycleModel(d);
        setRecycleShowModel(true);
        setTimeout(() => {
            localStorage.setItem('openRecycleBin', null!)
        }, 500);
    }
    const onClickRecycleHideModel = () => { setRecycleShowModel(false); }

    let callMeAPI = (token: string, controller: AbortController) => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            if (selectedSite.id === 'onedrive') {
                setLoading2(false);
                setIsOneDrive(true);
                return;
            }

            setIsOneDrive(false);

            instance
                .post(domainurl + "/meRB", { site: selectedSite.webUrl })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */

                    const d: any[] = response.data.recyclebin.reverse();
                    setDeleted([...d]);

                    let openRecycleBin = localStorage.getItem('openRecycleBin');
                    if (openRecycleBin && openRecycleBin !== 'null') {
                        var pp: any = JSON.parse(openRecycleBin) as any;
                        if (pp.Id) {
                            onClickRecycleShowModel(pp);
                        } else {
                            var dir = (pp!.webUrl) ? pp!.webUrl.replaceAll("%20", " ").toLowerCase() : "";

                            for (var i = 0; i < d.length; i++) {
                                var path = (d[i].DirName + '/' + d[i].LeafName).toLowerCase();
                                path = path.replaceAll("%20", " ");
                                if (dir && dir.includes(path)) {
                                    onClickRecycleShowModel(d[i]);
                                    break;
                                }
                            }
                        }
                    }
                    setLoading2(false);
                })
                .catch((error) => {
                    setLoading2(false);
                })
                .finally(() => { setLoading2(false); });
    }

    let callRestoreRecycleItemAPI = () => {
        const token = localStorage.getItem("token") as string;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        instance
            .post(domainurl + "/restoreRecycleItem", { site: selectedSite.webUrl, value: dataRecycleModel.Id || dataRecycleModel.id })
            .then((response) => {
                /* Start Check for Errors */
                let ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */
                const controller = new AbortController();
                callMeAPI(token, controller);
                onClickRecycleHideModel();
            })
            .catch((error) => {
            })
            .finally(() => { });
    }

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    /*useEffect(() => {
        const controller = new AbortController();
        let ignore = false;
        if (!ignore) {
            setLoading(true);
            setLoading2(true);
            callMeAPI("true", controller);
        }
        return () => {
            controller.abort();
            ignore = true;
        }
    }, []);*/

    let refreshC = () => {
        const controller = new AbortController();
        refresh(controller);
    }
    let refresh = (controller: AbortController) => {
        callMeAPI("true", controller);
    }

    useEffect(() => {
        const controller = new AbortController();
        refresh(controller);
        return () => {
            controller.abort();
        }
    }, [selectedSite]);

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            <div className="headerFolders">
                <div className="splash">Recycle Bin</div>
                <div onClick={refreshC} className="refresh"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                    <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                    <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                </svg></div>
            </div>
            <div className="three three-recycle">
                <div className="scroll">
                    <strong>
                        {(loading2) ? <div className="">
                            <svg className="circlespinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                            </svg>
                        </div> :
                            <div>
                                <div>
                                    <span className="table-name">Name</span>
                                    <span className="table-deletion">Deletion</span>
                                    <span className="table-date">Date</span>
                                </div>
                                <span className=""><input onChange={event => setQuery(event.target.value)} className="SearchBarSites RecycleBinSearch" type="text" placeholder="Search Recycle Bin ..." /></span>
                                <div className="table-main">{
                                    (isOneDrive) ? <div>To view the recycling bin, please click on this link. <a target="_blank" href={"https://" + organizationName + "-my.sharepoint.com/"} rel="noreferrer">Click Recycling Bin</a></div> : (deleted.length > 0) ? deleted.filter((ss: any) => {
                                        if (query === '') {
                                            return ss;
                                        } else if (ss.Title.toLowerCase().includes(query.toLowerCase())) {
                                            return ss;
                                        }
                                    }).slice((pageNumber - 1) * perLimit, pageNumber * perLimit).map((d, i) => (
                                        <div className="item" onClick={() => onClickRecycleShowModel(d)} key={i}>
                                            <span>{d["Title"]}</span>
                                            <strong> </strong>
                                            <span className="AlignRight">{moment(d['DeletedDate']).format('l LT')}</span>
                                            <strong> </strong>
                                            <span className="AlignRight2">{((8035200000 - ((new Date()).getTime() - (new Date(d['DeletedDate'])).getTime())) / (1000 * 60 * 60 * 24)).toFixed(0)} Days</span>
                                        </div>
                                    )) : <span>No items in recycling bin</span>}</div>
                                <div className="paginate-scroll">
                                    <div className="page pageNumberC">
                                        <span className="pageNumber" onClick={() => { setPageNumber(1) }}>&lt;</span><span>{
                                            [...Array(Math.ceil(deleted.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if (ss.Title.toLowerCase().includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) > 0 ? Math.ceil(deleted.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if (ss.Title.toLowerCase().includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) : 1)].map((x, i) => (
                                                <span className={(pageNumber === (i + 1)) ? 'pageNumber selected' : ((((pageNumber - (i + 1)) <= 3) && ((pageNumber - (i + 1)) >= -3)) ? 'pageNumber' : 'pageNumber hide')} onClick={() => { setPageNumber(i + 1) }}>{(i + 1)}</span>
                                            ))
                                        }</span><span className="pageNumber" onClick={() => {
                                            setPageNumber(Array(Math.ceil(deleted.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if (ss.Title.toLowerCase().includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) > 0 ? Math.ceil(deleted.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if (ss.Title.toLowerCase().includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) : 1).length)
                                        }}>&gt;</span>
                                    </div>
                                    <div className="pagelimit">
                                        <div className="pageperpage">Per Page Limit: </div>
                                        <select onChange={(e) => { setPerLimit(parseInt(e.target.value)); setPageNumber(1); }} className="pageperpage">
                                            <option value="20">20</option>
                                            <option value="40">40</option>
                                            <option value="60">60</option>
                                            <option value="80">80</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }</strong>
                    <footer>Recycle Bin</footer>
                </div>
            </div>
            {(recycleShowModel) ?
                <div onClick={onClickRecycleHideModel} className="model6">
                    <span className="model-close">X</span>
                    <div onClick={(e) => { e.stopPropagation(); }} className="model-body">
                        <div className="model-body-header">
                            <span>{dataRecycleModel.Title}</span>
                        </div>
                        <div onClick={onClickRecycleHideModel} className="close">X</div>
                        <div className="model-body-content2">
                            <div>
                                <div className="small-box">{dataRecycleModel.DirName}</div>
                                <div className="small-box">{dataRecycleModel.Title}</div>
                                <div className="small-box">{dataRecycleModel.AuthorName}</div>
                                <div className="small-box">{moment(dataRecycleModel['DeletedDate']).format('l LT')}</div>
                                <div className="small-box">{dataRecycleModel.DeletedByName}</div>
                                <div className="small-box">{(dataRecycleModel.ItemType === 1) ? "FIle" : "Folder"}</div>
                                <div className="small-box">{dataRecycleModel.Size}</div>
                                <div>
                                    <button className="restore-item" onClick={callRestoreRecycleItemAPI}>Restore Item</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <></>}
        </>
    )
}