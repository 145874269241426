import { useState, useEffect, ReactNode } from "react";
import "./../App.css";

import { useNavigate } from "react-router-dom";
import moment from 'moment'

import axios from "axios";
import { useOutletContext } from "react-router-dom";

import { flash } from "./flash";

const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config

export let Report = () => {
    const outlet = useOutletContext() as any;
    const selectedSite = outlet.selectedSite;
    const sites = outlet.sites;

    const [reports, setReports] = useState([] as any[]);
    const [perLimit, setPerLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);

    const [redirectDirectory, setRedirectDirectory] = useState(false);
    const [redirectRecycleBin, setRedirectRecycleBin] = useState(false);

    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');

    const [query, setQuery] = useState("");

    const token = localStorage.getItem("token") as string;
    const _id = document.getElementById("report-date-selection") as HTMLInputElement;

    let navigate = useNavigate();

    let OnClickRecycleShowModel = async (d: any) => {
        if (d.deleted || d.type === "deleted") {
            if (selectedSite.id !== 'onedrive') {
                localStorage.setItem('openRecycleBin', JSON.stringify(d));
                setRedirectRecycleBin(true);
            }
        } else {
            localStorage.setItem('openDirectory', JSON.stringify(d));
            setRedirectDirectory(true);
        }
    }

    let callReportsAPI = (isLatest: boolean, controller: AbortController) => {
        setTimeout(() => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

            instance
                .post(domainurl + "/reports", { site: selectedSite.id, value: (isLatest) ? 'latest' : _id?.value, timezone: timeZone })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Start Check for Errors */

                    setReports(response.data.value);
                    setLoading2(false);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0);
    }

    let callRecycleItemAPI = (controller: AbortController) => {
        const token = localStorage.getItem("token") as string;

        const instance = axios.create({
            signal: controller.signal,
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        instance
            .post(domainurl + "/recycleItem", { site: selectedSite.webUrl, value: JSON.parse(localStorage.getItem('openRecycleBin')!).id })
            .then((response) => {
                /* Start Check for Errors */
                let ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                if (response.data.Id) {
                    localStorage.setItem('openRecycleBin', JSON.stringify(response.data!));
                }
                return navigate("/recyclebin");
            })
            .catch((error) => {
            })
            .finally(() => { });
    }

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    let reportsC = () => {
        const controller = new AbortController();
        callReportsAPI(false, controller);
    }

    /*useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        setLoading2(true);
        callReportsAPI(false, controller);
        return () => {
            controller.abort();
        }
    }, []);*/

    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substring(0, 10);

    useEffect(() => {
        const controller = new AbortController();
        if (redirectDirectory) {
            return navigate("/directory");
        }
        if (redirectRecycleBin) {
            callRecycleItemAPI(controller);
        }
        return () => {
            controller.abort();
        }
    }, [redirectDirectory, redirectRecycleBin]);
    useEffect(() => {
        const controller = new AbortController();
        callReportsAPI(false, controller);
        return () => {
            controller.abort();
        }
    }, [selectedSite]);

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            <div className="headerFolders">
                <div className="splash">Report</div>
            </div>
            <div className="three three-report">
                <div className="scroll">
                    <div className="report-all">
                        <span className="report-container">
                            <input id="report-date-selection" className="report-date-selection" type="date" defaultValue={date} max={date} />
                        </span>
                        <span className="report-container">
                            <button onClick={reportsC}>Generate Report</button>
                            <strong></strong>
                            <button className="ahref" onClick={reportsC}>Latest</button>
                        </span>
                    </div>
                    <span className=""><input onChange={event => setQuery(event.target.value)} className="SearchBarSites ReportSearch" type="text" placeholder="Search Reports ..." /></span>
                    <strong>
                        {(loading2) ? <div className="">
                            <svg className="circlespinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                            </svg>
                        </div> : (reports.length > 0) ? reports.filter((ss: any) => {
                            if (query === '') {
                                return ss;
                            } else if (((ss['deleted']) ? ss['filename'] + "Added to Recycling Bin" : (ss["name"]) ? ss["name"] : (ss["type"]) ? ss["type"] + ' ' + ss["webUrl"] : "Unknown Event").toLowerCase().includes(query.toLowerCase())) {
                                return ss;
                            }
                        }).slice((pageNumber - 1) * perLimit, pageNumber * perLimit).map((d, i) => (
                            <div className="item" onClick={() => OnClickRecycleShowModel(d)} key={i}>
                                <span>{(d['deleted']) ? d['filename'] + " Added to Recycling Bin" : (d["name"]) ? d["name"] : (d["type"]) ? d["type"] + ' ' + d["webUrl"] : "Unknown Event"}</span>
                                <strong> </strong>
                                <span className="AlignRight AlignRightRep">{(d['deleted']) ? "Added to Recycling Bin" : moment(d['lastModifiedDateTime']).format('l LT')}</span>
                            </div>
                        )) : <span>No report items</span>}
                    </strong>
                    <div className="paginate-scroll">
                        <div className="page pageNumberC">
                            <span className="pageNumber" onClick={() => { setPageNumber(1) }}>&lt;</span><span>{
                                [...Array(Math.ceil(reports.filter((ss: any) => {
                                    console.log(ss);
                                    if (query === '') {
                                        return ss;
                                    } else if (((ss['deleted']) ? ss['filename'] + " " : (ss["name"]) ? ss["name"] : (ss["type"]) ? ss["type"] + ' ' + ss["webUrl"] : "").toLowerCase().includes(query.toLowerCase())) {
                                        return ss;
                                    }
                                }).length / perLimit) > 0 ? Math.ceil(reports.filter((ss: any) => {
                                    if (query === '') {
                                        return ss;
                                    } else if (((ss['deleted']) ? ss['filename'] + " " : (ss["name"]) ? ss["name"] : (ss["type"]) ? ss["type"] + ' ' + ss["webUrl"] : "").toLowerCase().includes(query.toLowerCase())) {
                                        return ss;
                                    }
                                }).length / perLimit) : 1)].map((x, i) => (
                                    <span className={(pageNumber === (i + 1)) ? 'pageNumber selected' : ((((pageNumber - (i + 1)) <= 3) && ((pageNumber - (i + 1)) >= -3)) ? 'pageNumber' : 'pageNumber hide')} onClick={() => { setPageNumber(i + 1) }}>{(i + 1)}</span>
                                ))
                            }</span><span className="pageNumber" onClick={() => {
                                setPageNumber(Array(Math.ceil(reports.filter((ss: any) => {
                                    if (query === '') {
                                        return ss;
                                    } else if (((ss['deleted']) ? ss['filename'] + " " : (ss["name"]) ? ss["name"] : (ss["type"]) ? ss["type"] + ' ' + ss["webUrl"] : "").toLowerCase().includes(query.toLowerCase())) {
                                        return ss;
                                    }
                                }).length / perLimit) > 0 ? Math.ceil(reports.filter((ss: any) => {
                                    if (query === '') {
                                        return ss;
                                    } else if (((ss['deleted']) ? ss['filename'] + " " : (ss["name"]) ? ss["name"] : (ss["type"]) ? ss["type"] + ' ' + ss["webUrl"] : "").toLowerCase().includes(query.toLowerCase())) {
                                        return ss;
                                    }
                                }).length / perLimit) : 1).length)
                            }}>&gt;</span>
                        </div>
                        <div className="pagelimit">
                            <div className="pageperpage">Per Page Limit: </div>
                            <select onChange={(e) => { setPerLimit(parseInt(e.target.value)); setPageNumber(1); }} className="pageperpage">
                                <option value="20">20</option>
                                <option value="40">40</option>
                                <option value="60">60</option>
                                <option value="80">80</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                    <footer>Reports</footer>
                </div>
            </div>
        </>
    )
}