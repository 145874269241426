import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { flash } from "./flash";
import axios from "axios";
const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config
export let About = () => {
    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');
    const [forgotDomain, setForgotDomain] = useState("");
    const ttoken = localStorage.getItem("token");
    const callForgotDomainC = () => () => {
        const token = ttoken;
        const instance = axios.create({
            withCredentials: true
        });
        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });
        instance
            .post(domainurl + "/forgotDomain", { email: forgotDomain })
            .then((response) => {
                /* Start Check for Errors */
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */
            })
            .catch((error) => { })
            .finally(() => { });
    }

    return (
        <>
            <div className="html"></div>
            <div className="App">
                <div className="about">
                    <h2>Forgot Domain?</h2>
                    <p className="forgotdomain">
                        <a>
                            <input className="forgotYourDomainTxt" type="text" placeholder="Email Address..." onChange={event => setForgotDomain(event.target.value)} />
                            <br />
                            <button className="forgotYourDomain" onClick={callForgotDomainC()}>Send Email?</button>
                        </a>
                    </p>
                    <nav>
                        <p className="forgotdomain">
                            <a>
                                <button className="forgotYourDomain nobg"><Link to="/">Home</Link></button>
                            </a>
                        </p>
                    </nav>
                </div>
            </div>
        </>
    );
}