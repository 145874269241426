import { useState, useEffect } from "react";
import "./../App.css";
import 'react-tabs/style/react-tabs.css';
import 'react-accessible-accordion/dist/fancy-example.css';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import axios from "axios";
import { useOutletContext } from "react-router-dom";

import { flash } from "./flash";

export let Inventory = () => {

    const outlet = useOutletContext() as any;
    const selectedClient = outlet.selectedClient;
    const [currentClient, setCurrentClient] = useState("");

    
    const [hardwares, setHardwares] = useState([] as any[]);
    const [computers, setComputers] = useState([] as any[]);
    const [printers, setPrinters] = useState([] as any[]);
    const [servers, setServers] = useState([] as any[]);
    const [employees, setEmployees] = useState([] as any[]);
    const [employeeComputers, setEmployeeComputers] = useState([] as any[]);

    const [assignNewEmployee, setAssignNewEmployee] = useState(false);
    const [assignedEmployee, setAssignedEmployee] = useState("");

    const [deviceDetails, setDeviceDetails] = useState({ type: "", show: false, edit: false });

    const [deleteDeviceConfirm, setDeleteDeviceConfirm] = useState(false);
    const [deleteEmployeeConfirm, setDeleteEmployeeConfirm] = useState(false);


    const [selectedDevice, setSelectedDevice] = useState({id: "", name: "" });

    const [deviceType, setDeviceType] = useState("computer" as keyof Hardware);

    const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
    const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config

    interface Hardware {
        name: any;
        serial_number: any;
        computer: any;
        server: any;
        printer: any;
    }

    const [hardwareData, setHardwareData] = useState({

        name: "",
        serial_number: "",
        computer: {
            os: "",
            ram: 0,
            cpu: "",
            type: "",
            location: "",
            mac_address: "",
            storage_type: "",
            storage_capacity: 0,
            password_user: "",
            password_local_admin: "",
            teamviewer_id: "",
            teamviewer_password: ""
        },
        server: {
            os: "",
            ram: 0,
            cpu: "",
            password_local_admin: "",
            teamviewer_id: "",
            teamviewer_password: ""
        },
        printer: {
            username: "",
            password: "",
            location: "",
            ip_address: ""
        }
    } as unknown as Hardware);

    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');

    const [loaded, setLoaded] = useState(false);

    const [device, setDevice] = useState({
        name: "",
        serial_number: "",
        ...hardwareData.computer,
        ...hardwareData.server,
        ...hardwareData.printer
    });

    useEffect(() => {
        const controller = new AbortController();
        getEmployees(controller);
        onChangeHandler(deviceType, 'storage_type', "SSD");
        onChangeHandler(deviceType, 'type', "Desktop");
        return () => {
            controller.abort();
        }
    }, [loaded]);

    if (!loaded) {
        setLoaded(true);
    }

    let createDevice = () => {
        const controller = new AbortController();
        const clientRef = selectedClient.id;
        //console.log(clientRef);
        const instance = axios.create({
            signal: controller.signal,
            withCredentials: true
        });
        console.log("axios ok");
        var str: keyof Hardware = deviceType as keyof Hardware;

        instance
            .post(domainurl + "/createDevice", {
                name: hardwareData.name, serial_number: hardwareData.serial_number,
                client: clientRef, data: JSON.stringify(hardwareData[str]), type: deviceType
            })
            .then((response) => {
                let token = localStorage.getItem("token");
                if (!token || token === 'null') {
                    return;
                }

                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                
                getDevices("", controller);
            })
            .catch((error) => {
                console.log(error);
                if (flash(error.response, setFlashError, setFlashWarning, setFlashMessage)) return;
            })
            .finally(() => { });
    }

    const getController = (type: string) => (event : any) => {
        const controller = new AbortController();

        switch (type) {
            case "computer":
            case "printer":
            case "server":
                getDevices(type, controller);
                break;
            case "":
                getEmployees(controller);
                break;
            default:
                getEmpComputers(type, controller);
                break;

        }        
    }

    let getDevice = (id: string) => {
        const controller = new AbortController();
        if (selectedClient.id !== 'null' && selectedClient.id !== undefined) {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });
            instance
                .post(domainurl + "/fetchDevice", { id: id })
                .then((response) => {

                    let token = localStorage.getItem("token");
                    if (!token || token === 'null') {
                        return;
                    }

                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;

                    setDevice(response.data.hardware);

                })
                .catch((error) => {
                    console.log(error);
                    if (flash(error.response, setFlashError, setFlashWarning, setFlashMessage)) return;
                })
                .finally(() => { });
        } else {
            console.log("No Client Selected");
        }
    }

    let getDevices = (deviceType: string, controller: AbortController) => {

        if (selectedClient.id !== 'null' && selectedClient.id !== undefined) {
            const clientRef = selectedClient.id;
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });
            instance
                .post(domainurl + "/fetchDeviceList", { client: clientRef, deviceType: deviceType })
                .then((response) => {

                    let token = localStorage.getItem("token");
                    if (!token || token === 'null') {
                        return;
                    }

                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;

                    switch (deviceType) {
                        case "computer":
                            setComputers([...response.data.hardwares]);
                            break;
                        case "printer":
                            setPrinters([...response.data.hardwares]);
                            break;
                        case "server":
                            setServers([...response.data.hardwares]);
                            break;
                        default:
                            setHardwares([...response.data.hardwares]);
                            break;

                    }

                    
                })
                .catch((error) => {
                    console.log(error);
                    if (flash(error.response, setFlashError, setFlashWarning, setFlashMessage)) return;
                })
                .finally(() => { });
        } else {
            console.log("No Client Selected");
        }
    }

    let updateDevice = (id: string) => {
        if (selectedClient.id !== 'null' && selectedClient.id !== undefined) {
            const controller = new AbortController();
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });
            console.log("axios ok");
            var str: keyof Hardware = deviceType as keyof Hardware;

            instance
                .post(domainurl + "/updateDevice", {
                    id: id, name: hardwareData.name, serial_number: hardwareData.serial_number,
                    data: JSON.stringify(hardwareData[str]), type: deviceType
                })
                .then((response) => {

                    let token = localStorage.getItem("token");
                    if (!token || token === 'null') {
                        return;
                    }

                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    const controller = new AbortController();
                    getDevices(deviceType, controller);
                    setDeviceDetails({ type: "", show: false, edit: false });

                })
                .catch((error) => {
                    console.log(error);
                    if (flash(error.response, setFlashError, setFlashWarning, setFlashMessage)) return;
                })
                .finally(() => { });
        } else {
            console.log("No Client Selected");
        }
    }

    let deleteDevice = (id: string) => {
        if (selectedClient.id !== 'null' && selectedClient.id !== undefined) {
            const controller = new AbortController();
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });
            console.log("axios ok");
            instance
                .post(domainurl + "/deleteDevice", {
                    id: id, type: deviceType
                })
                .then((response) => {

                    let token = localStorage.getItem("token");
                    if (!token || token === 'null') {
                        return;
                    }

                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    getDevices(deviceType, controller);
                    setDeviceDetails({ type: "", show: false, edit: false });
                })
                .catch((error) => {
                    console.log(error);
                    if (flash(error.response, setFlashError, setFlashWarning, setFlashMessage)) return;
                })
                .finally(() => { });
        } else {
            console.log("No Client Selected");
        }
    }

    let updateHandler = (id: string, type: string) => {
        setDeviceType(type as keyof Hardware);
        updateDevice(id);
    }

    let deleteHandler = (id: string, type: string) => {
        if (type === "Employee") {
            //delete
        } else {
            setDeviceType(type as keyof Hardware);
            deleteDevice(id);
        }
        
        setDeleteDeviceConfirm(false);
    }

    let getEmployees = (controller: AbortController) => {
        if (selectedClient.id !== 'null' && selectedClient.id !== undefined) {
            const clientRef = selectedClient.id;
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            instance
                .post(domainurl + "/employees", { client: clientRef })
                .then((response) => {

                    let token = localStorage.getItem("token");
                    if (!token || token === 'null') {
                        return;
                    }

                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;

                    setEmployees([...response.data.employees]);

                })
                .catch((error) => {
                    console.log(error);
                    if (flash(error.response, setFlashError, setFlashWarning, setFlashMessage)) return;
                })
                .finally(() => {
                    
                });
        } else {
            console.log("No Client Selected");
        }
    }

    let getEmpComputers = (employee: string, controller: AbortController) => {
        const instance = axios.create({
            signal: controller.signal,
            withCredentials: true
        });
        instance
            .post(domainurl + "/fetchEmpDevices", { employee: employee })
            .then((response) => {

                let token = localStorage.getItem("token");
                if (!token || token === 'null') {
                    return;
                }

                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;

                setEmployeeComputers([...response.data.hardwares]);
            })
            .catch((error) => {
                console.log(error);
                if (flash(error.response, setFlashError, setFlashWarning, setFlashMessage)) return;
            })
            .finally(() => { });
    }


    //in progress
    let deleteEmployee = (id: string) => {
        if (selectedClient.id !== 'null' && selectedClient.id !== undefined) {
            const controller = new AbortController();
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });
            console.log("axios ok");
            instance
                .post(domainurl + "/deleteEmployee", {
                    id: id
                })
                .then((response) => {

                    let token = localStorage.getItem("token");
                    if (!token || token === 'null') {
                        return;
                    }

                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    getEmployees(controller);
                    getDevices(deviceType, controller);
                    setDeleteEmployeeConfirm(false);

                })
                .catch((error) => {
                    console.log(error);
                    if (flash(error.response, setFlashError, setFlashWarning, setFlashMessage)) return;
                })
                .finally(() => { });
        } else {
            console.log("No Client Selected");
        }
    }

    let assignEmployee = (id: string) => {
        console.log("Assigning to " + assignedEmployee);
        const controller = new AbortController();
        const employee = assignedEmployee;

        const instance = axios.create({
            signal: controller.signal,
            withCredentials: true
        });
        console.log("axios ok");

        instance
            .post(domainurl + "/assignEmployee", { id: id, employee: employee })
            .then((response) => {
                let token = localStorage.getItem("token");
                if (!token || token === 'null') {
                    return;
                }

                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                const controller = new AbortController();
                getDevices("computer", controller);
                setAssignNewEmployee(false);
            })
            .catch((error) => {
                console.log(error);
                if (flash(error.response, setFlashError, setFlashWarning, setFlashMessage)) return;
            })
            .finally(() => { });
    }

    function onChangeHandler(_deviceType: keyof Hardware, _name: string, _value: any) {
        let _o = hardwareData;
        if (_name === "value") {
            _o[_deviceType] = _value;

        } else {
            _o[_deviceType][_name] = _value;

        }
        setHardwareData({ ..._o })
    }

    useEffect(() => {
        const controller = new AbortController();
        if (currentClient !== selectedClient) {
            getDevices("computer", controller);
            getDevices("printer", controller);
            getDevices("server", controller);
            setCurrentClient(selectedClient);
            getEmployees(controller);
        }
        return () => {
            controller.abort();
        }
    }, [])

    if (currentClient !== selectedClient) {
        const controller = new AbortController();
        getDevices("computer", controller);
        getDevices("printer", controller);
        getDevices("server", controller);
        setCurrentClient(selectedClient);
        getEmployees(controller);
    }

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            <div className="headerFolders">
                <span className="newFolder">
                    
                </span>
                <div className="splash">Device Inventory</div>
                <div className="refresh">
                    
                </div>
            </div>
            <div className="three">
                <div className="inventory-body">

                    <Tabs>
                        <TabList>
                            <Tab>New Device</Tab>
                            <Tab onClick={getController("computer")}>Computers</Tab>
                            <Tab onClick={getController("printer")}>Printers</Tab>
                            <Tab onClick={getController("server")}>Servers</Tab>
                            <Tab onClick={getController("")}>Employees</Tab>
                        </TabList>

                        <TabPanel name="new-device">
                            <div className="small-box">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Name:</td>
                                            <td>
                                                <input type="text" id="name" name="name" onChange={(event) => {
                                                    onChangeHandler("name", "value", event.target.value)
                                                }}></input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Serial Number:</td>
                                            <td>
                                                <input type="text" id="sn" name="sn" onChange={(event) => {
                                                    onChangeHandler("serial_number", "value", event.target.value)
                                                }}></input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Device:</td>
                                            <td>
                                                <select name="types" id="types" value={deviceType} onChange={(event) => {
                                                    setDeviceType(event.target.value as keyof Hardware)
                                                }}>
                                                    <option value="computer" >Computer</option>
                                                    <option value="server">Server</option>
                                                    <option value="printer">Printer</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div className="small-box">
                                <>{(deviceType === "computer") ? <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>OS:</td>
                                                <td>
                                                    <input id="os" name="os" onChange={(event) => {
                                                        onChangeHandler(deviceType, 'os', event.target.value)
                                                    }}></input>
                                                </td>
                                                <td>RAM:</td>
                                                <td>
                                                    <input type="number" id="ram" name="ram" onChange={(event) => {
                                                        onChangeHandler(deviceType, 'ram', parseInt(event.target.value));
                                                    }}></input>
                                                </td>
                                                <td>CPU:</td>
                                                <td>
                                                    <input id="cpu" name="cpu" onChange={(event) => {
                                                        onChangeHandler(deviceType, 'cpu', event.target.value)
                                                    }}></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Mac Address:</td>
                                                <td>
                                                    <input id="mac" name="mac" onChange={(event) => {
                                                        onChangeHandler(deviceType, 'mac_address', event.target.value)
                                                    }}></input>
                                                </td>
                                                <td>Storage Capacity:</td>
                                                <td>
                                                    <input id="strcap" name="strcap" onChange={(event) => {
                                                        onChangeHandler(deviceType, 'storage_capacity',
                                                            parseInt(event.target.value));
                                                    }}></input>
                                                </td>
                                                <td>Storage Type:</td>
                                                <td>
                                                    <select name="str" id="str" onChange={(event) => {
                                                        onChangeHandler(deviceType, 'storage_type', event.target.value)
                                                    }}>
                                                        <option value="SSD" >SSD</option>
                                                        <option value="HDD">HDD</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>User PW:</td>
                                                <td>
                                                    <input type="password" id="userpw" name="userpw" onChange={(event) => { onChangeHandler(deviceType, 'password_user', event.target.value) }}></input>
                                                </td>
                                                <td>Local Admin PW:</td>
                                                <td>
                                                    <input type="password" id="adminpw" name="adminpw" onChange={(event) => { onChangeHandler(deviceType, 'password_local_admin', event.target.value) }}></input>
                                                </td>
                                                <td>Computer Type:</td>
                                                <td>
                                                    <select name="type" id="type" onChange={(event) => { onChangeHandler(deviceType, 'type', event.target.value) }}>
                                                        <option value="Desktop" >Desktop</option>
                                                        <option value="Laptop">Laptop</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Location:</td>
                                                <td>
                                                    <input id="loc" name="loc" onChange={(event) => { onChangeHandler(deviceType, 'location', event.target.value) }}></input>
                                                </td>
                                                <td>Teamviewer ID:</td>
                                                <td>
                                                    <input id="teamid" name="teamid" onChange={(event) => { onChangeHandler(deviceType, 'teamviewer_id', event.target.value) }}></input>
                                                </td>
                                                <td>Teamviewer PW:</td>
                                                <td>
                                                    <input type="password" id="teampw" name="teampw" onChange={(event) => { onChangeHandler(deviceType, 'teamviewer_password', event.target.value) }}></input>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> : <></>
                                }</>

                                <>{(deviceType === "server") ? <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>OS:</td>
                                                <td>
                                                    <input id="os" name="os" onChange={(event) => { onChangeHandler(deviceType, 'os', event.target.value) }}></input>
                                                </td>
                                                <td>RAM:</td>
                                                <td>
                                                    <input type="number" id="ram" name="ram" onChange={(event) => { onChangeHandler(deviceType, 'ram', parseInt(event.target.value)); }}></input>
                                                </td>
                                                <td>
                                                    CPU:
                                                </td>
                                                <td>
                                                    <input id="cpu" name="cpu" onChange={(event) => { onChangeHandler(deviceType, 'cpu', event.target.value) }}></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Local Admin PW:</td>
                                                <td>
                                                    <input id="adminpw" name="adminpw" onChange={(event) => { onChangeHandler(deviceType, 'password_local_admin', event.target.value) }}></input>
                                                </td>
                                                <td>Teamviewer ID:</td>
                                                <td>
                                                    <input id="teamid" name="teamid" onChange={(event) => { onChangeHandler(deviceType, 'teamviewer_id', event.target.value) }}></input>
                                                </td>
                                                <td>Teamviewer PW:</td>
                                                <td>
                                                    <input id="teampw" name="teampw" onChange={(event) => { onChangeHandler(deviceType, 'teamviewer_password', event.target.value) }}></input>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> : <></>
                                }</>

                                <>{(deviceType === "printer") ? <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Username:</td>
                                                <td>
                                                    <input id="user" name="user" onChange={(event) => { onChangeHandler(deviceType, 'username', event.target.value) }}></input>
                                                </td>
                                                <td>Password:</td>
                                                <td>
                                                    <input id="pw" name="pw" onChange={(event) => { onChangeHandler(deviceType, 'password', event.target.value) }}></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Location:</td>
                                                <td>
                                                    <input id="loc" name="loc" onChange={(event) => { onChangeHandler(deviceType, 'location', event.target.value) }}></input>
                                                </td>
                                                <td>IP Address:</td>
                                                <td>
                                                    <input id="ip" name="ip" onChange={(event) => { onChangeHandler(deviceType, 'ip_address', event.target.value) }}></input>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> : <></>
                                }</>

                                <div>
                                    <button onClick={createDevice}> Enter Info </button>
                                </div>


                            </div>
                        </TabPanel>
                        <TabPanel name="computer">
                            <div className="InventoryList small-box">{
                                (computers.length > 0) ? <div>{
                                    computers.map((h, i) => (
                                        <div className="item" key={i} onClick={() => {
                                            setSelectedDevice({ id: h._id, name: h.name }); setDeviceType("computer");
                                            getDevice(h._id); setDeviceDetails({ type: "computer", show: true, edit: false });
                                        }}>
                                            <span> {h.name} </span>
                                            <span> {h.serial_number} </span>
                                            <span> {h.type} </span>
                                            <span> {h.os} </span>
                                            <span> {h.ram} </span>
                                            <span> {h.cpu} </span>
                                            <span> {h.location} </span>
                                            <span> {h.mac_address} </span>
                                            <span> {h.storage} </span>
                                            <span> {h.storage_type} </span>
                                            {(h.employee) ? <span> {h.employee} </span>
                                                : <span> No Employee Assigned </span>}
                                        </div>
                                    ))
                                }</div> : <div>No Computers to Show</div>
                            }
                                
                            </div>
                        </TabPanel>
                        <TabPanel name="printer">
                            <div className="InventoryList small-box">{
                                (printers.length > 0) ? <div>{
                                    printers.map((h, i) => (
                                        <div className="item" key={i} onClick={() => {
                                            setSelectedDevice({ id: h._id, name: h.name }); setDeviceType("printer");
                                            getDevice(h._id); setDeviceDetails({ type: "printer", show: true, edit: false });
                                        }}>
                                            <span> {h.name}</span>
                                            <span> {h.serial_number} </span>
                                            <span> {h.username} </span>
                                            <span> {h.location} </span>
                                            <span> {h.ip_address} </span>
                                        </div>
                                    ))
                                }</div> : <div>No Printers to Show</div>
                            }

                            </div>
                        </TabPanel>
                        <TabPanel name="server">
                            <div className="InventoryList small-box">{
                                (servers.length > 0) ? <div>{
                                    servers.map((h, i) => (
                                        <div className="item" key={i} onClick={() => {
                                            setSelectedDevice({ id: h._id, name: h.name }); setDeviceType("server");
                                            getDevice(h._id); setDeviceDetails({ type: "server", show: true, edit: false });
                                        }}>
                                            <span> {h.name}</span>
                                            <span> {h.serial_number} </span>
                                            <span> {h.os} </span>
                                            <span> {h.ram} </span>
                                            <span> {h.cpu} </span>
                                        </div>
                                    ))
                                }</div> : <div>No Servers to Show</div>
                            }

                            </div>
                        </TabPanel>
                        <TabPanel name="Employees">
                            <div className="InventoryList small-box">{
                                (employees.length > 0) ? <Accordion>{
                                    employees.map((e, i) => (
                                        <AccordionItem key={i}>
                                            <AccordionItemHeading className="item" onClick={getController(e._id)}>
                                                <AccordionItemButton>
                                                    {e.firstName + " " + e.lastName}
                                                </AccordionItemButton>
                                                <AccordionItemButton>
                                                    <button className="action-item" onClick={() => {
                                                        setDeleteEmployeeConfirm(true);
                                                    }}>Delete</button>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            {(employeeComputers.length > 0) ? <div>
                                                {employeeComputers.map((c, i) => (
                                                    <AccordionItemPanel key={i}>
                                                        <span> {c.name}</span>
                                                        <span> {c.serial_number} </span>
                                                        <span> {c.type} </span>
                                                        <span> {c.os} </span>
                                                        <span> {c.ram} </span>
                                                        <span> {c.cpu} </span>
                                                        <span> {c.location} </span>
                                                        <span> {c.mac_address} </span>
                                                        <span> {c.storage} </span>
                                                        <span> {c.storage_type} </span>
                                                    </AccordionItemPanel>
                                                ))}

                                            </div> : <AccordionItemPanel>No Computers Here</AccordionItemPanel>}
                                        </AccordionItem>
                                    ))}
                                </Accordion> : <div>No Employees to Show</div>
                            }

                            </div>
                        </TabPanel>

                    </Tabs>

                    {deviceDetails.show ?
                        <div>
                            <div className="model modelb">
                                <div className="modelConfirmTwo">
                                    <h2>{selectedDevice.name}</h2>
                                    <div className="modelContainer">
                                        <div>{
                                            deviceDetails.type === "computer" ?
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Name:</td>
                                                            <td>
                                                                {device.name}
                                                            </td>
                                                            <td>Serial Number:</td>
                                                            <td>
                                                                {device.serial_number}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>OS:</td>
                                                            <td>
                                                                {device.os}
                                                            </td>
                                                            <td>RAM:</td>
                                                            <td>
                                                                {device.ram}
                                                            </td>
                                                            <td>CPU:</td>
                                                            <td>
                                                                {device.cpu}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mac Address:</td>
                                                            <td>
                                                                {device.mac_address}
                                                            </td>
                                                            <td>Storage Capacity:</td>
                                                            <td>
                                                                {device.storage_capacity}
                                                            </td>
                                                            <td>Storage Type:</td>
                                                            <td>
                                                                {device.storage_type}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>User PW:</td>
                                                            <td>
                                                                {device.password_user}
                                                            </td>
                                                            <td>Local Admin PW:</td>
                                                            <td>
                                                                {device.password_local_admin}
                                                            </td>
                                                            <td>Computer Type:</td>
                                                            <td>
                                                                {device.type}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Location:</td>
                                                            <td>
                                                                {device.location}
                                                            </td>
                                                            <td>Teamviewer ID:</td>
                                                            <td>
                                                                {device.teamviewer_id}
                                                            </td>
                                                            <td>Teamviewer PW:</td>
                                                            <td>
                                                                {device.teamviewer_password}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : <></>}
                                            {deviceDetails.type === "server" ?
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Name:</td>
                                                            <td>
                                                                {device.name}
                                                            </td>
                                                            <td>Serial Number:</td>
                                                            <td>
                                                                {device.serial_number}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>OS:</td>
                                                            <td>
                                                                {device.os}
                                                            </td>
                                                            <td>RAM:</td>
                                                            <td>
                                                                {device.ram}
                                                            </td>
                                                            <td>
                                                                CPU:
                                                            </td>
                                                            <td>
                                                                {device.cpu}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Local Admin PW:</td>
                                                            <td>
                                                                {device.password_local_admin}
                                                            </td>
                                                            <td>TeamViewer ID:</td>
                                                            <td>
                                                                {device.teamviewer_id}
                                                            </td>
                                                            <td>TeamViewer PW:</td>
                                                            <td>
                                                                {device.teamviewer_password}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : <></>}
                                            {deviceDetails.type === "printer" ?
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Name:</td>
                                                            <td>
                                                                {device.name}
                                                            </td>
                                                            <td>Serial Number:</td>
                                                            <td>
                                                                {device.serial_number}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Username:</td>
                                                            <td>
                                                                {device.username}
                                                            </td>
                                                            <td>Password:</td>
                                                            <td>
                                                                {device.password}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Location:</td>
                                                            <td>
                                                                {device.location}
                                                            </td>
                                                            <td>IP Address:</td>
                                                            <td>
                                                                {device.ip_address}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : <></>}
                                        </div>
                                    </div>
                                    <div className="modelFooter">
                                        <button className="action-item" onClick={() => {
                                            setDeviceDetails({ type: deviceDetails.type, show: false, edit: true });
                                        }}>Edit</button>
                                        <button className="ahref" onClick={() => {
                                            setDeviceDetails({ type: "", show: false, edit: false });
                                        }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>}

                    {deviceDetails.edit ?
                        <div>
                            <div className="model modelb">
                                <div className="modelConfirmTwo">
                                    <h2>{selectedDevice.name}</h2>
                                    <div className="modelContainer">
                                        <div>{
                                            deviceDetails.type === "computer" ?
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Name:</td>
                                                            <td>
                                                                <input type="text" id="name" name="name" onChange={(event) => {
                                                                    onChangeHandler("name", "value", event.target.value)
                                                                }} defaultValue={device.name}></input>
                                                            </td>
                                                            <td>Serial Number:</td>
                                                            <td>
                                                                <input type="text" id="sn" name="sn" onChange={(event) => {
                                                                    onChangeHandler("serial_number", "value", event.target.value)
                                                                }} defaultValue={device.serial_number}></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>OS:</td>
                                                            <td>
                                                                <input id="os" name="os" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'os', event.target.value)
                                                                }} defaultValue={device.os}></input>
                                                            </td>
                                                            <td>RAM:</td>
                                                            <td>
                                                                <input type="number" id="ram" name="ram" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'ram', parseInt(event.target.value));
                                                                }} defaultValue={device.ram}></input>
                                                            </td>
                                                            <td>CPU:</td>
                                                            <td>
                                                                <input id="cpu" name="cpu" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'cpu', event.target.value)
                                                                }} defaultValue={device.cpu}></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mac Address:</td>
                                                            <td>
                                                                <input id="mac" name="mac" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'mac_address', event.target.value)
                                                                }} defaultValue={device.mac_address}></input>
                                                            </td>
                                                            <td>Storage Capacity:</td>
                                                            <td>
                                                                <input id="strcap" name="strcap" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'storage_capacity',
                                                                        parseInt(event.target.value));
                                                                }} defaultValue={device.storage_capacity}></input>
                                                            </td>
                                                            <td>Storage Type:</td>
                                                            <td>
                                                                <select name="str" id="str" defaultValue={device.storage_type}
                                                                    onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'storage_type', event.target.value)
                                                                }}>
                                                                    <option value="SSD">SSD</option>
                                                                    <option value="HDD">HDD</option>
                                                                </select >
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>User PW:</td>
                                                            <td>
                                                                <input type="password" id="userpw" name="userpw" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'password_user', event.target.value)
                                                                }} defaultValue={device.password_user}></input>
                                                            </td>
                                                            <td>Local Admin PW:</td>
                                                            <td>
                                                                <input type="password" id="adminpw" name="adminpw" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'password_local_admin', event.target.value)
                                                                }} defaultValue={device.password_local_admin}></input>
                                                            </td>
                                                            <td>Computer Type:</td>
                                                            <td>
                                                                <select name="type" id="type" defaultValue={device.type} onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'type', event.target.value)
                                                                }}>
                                                                    <option value="Desktop" >Desktop</option>
                                                                    <option value="Laptop">Laptop</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Location:</td>
                                                            <td>
                                                                <input id="loc" name="loc" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'location', event.target.value)
                                                                }} defaultValue={device.location}></input>
                                                            </td>
                                                            <td>Teamviewer ID:</td>
                                                            <td>
                                                                <input id="teamid" name="teamid" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'teamviewer_id', event.target.value)
                                                                }} defaultValue={device.teamviewer_id}></input>
                                                            </td>
                                                            <td>Teamviewer PW:</td>
                                                            <td>
                                                                <input type="password" id="teampw" name="teampw" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'teamviewer_password', event.target.value)
                                                                }} defaultValue={device.teamviewer_password}></input>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : <></>}
                                            {deviceDetails.type === "server" ? 
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Name:</td>
                                                            <td>
                                                                <input type="text" id="name" name="name" onChange={(event) => {
                                                                    onChangeHandler("name", "value", event.target.value)
                                                                }} defaultValue={device.name}></input>
                                                            </td>
                                                            <td>Serial Number:</td>
                                                            <td>
                                                                <input type="text" id="sn" name="sn" onChange={(event) => {
                                                                    onChangeHandler("serial_number", "value", event.target.value)
                                                                }} defaultValue={device.serial_number}></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>OS:</td>
                                                            <td>
                                                                <input id="os" name="os" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'os', event.target.value)
                                                                }} defaultValue={device.os}></input>
                                                            </td>
                                                            <td>RAM:</td>
                                                            <td>
                                                                <input type="number" id="ram" name="ram" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'ram', parseInt(event.target.value));
                                                                }} defaultValue={device.ram}></input>
                                                            </td>
                                                            <td>
                                                                CPU:
                                                            </td>
                                                            <td>
                                                                <input id="cpu" name="cpu" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'cpu', event.target.value)
                                                                }} defaultValue={device.cpu}></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Local Admin PW:</td>
                                                            <td>
                                                                <input id="adminpw" name="adminpw" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'password_local_admin', event.target.value)
                                                                }} defaultValue={device.password_local_admin}></input>
                                                            </td>
                                                            <td>Teamviewer ID:</td>
                                                            <td>
                                                                <input id="teamid" name="teamid" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'teamviewer_id', event.target.value)
                                                                }} defaultValue={device.teamviewer_id}></input>
                                                            </td>
                                                            <td>Teamviewer PW:</td>
                                                            <td>
                                                                <input id="teampw" name="teampw" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'teamviewer_password', event.target.value)
                                                                }} defaultValue={device.teamviewer_password}></input>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : <></>}
                                            {deviceDetails.type === "printer" ?
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Name:</td>
                                                            <td>
                                                                <input type="text" id="name" name="name" onChange={(event) => {
                                                                    onChangeHandler("name", "value", event.target.value)
                                                                }} defaultValue={device.name}></input>
                                                            </td>
                                                            <td>Serial Number:</td>
                                                            <td>
                                                                <input type="text" id="sn" name="sn" onChange={(event) => {
                                                                    onChangeHandler("serial_number", "value", event.target.value)
                                                                }} defaultValue={device.serial_number}></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Username:</td>
                                                            <td>
                                                                <input id="user" name="user" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'username', event.target.value)
                                                                }} defaultValue={device.username}></input>
                                                            </td>
                                                            <td>Password:</td>
                                                            <td>
                                                                <input id="pw" name="pw" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'password', event.target.value)
                                                                }} defaultValue={device.password}></input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Location:</td>
                                                            <td>
                                                                <input id="loc" name="loc" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'location', event.target.value)
                                                                }} defaultValue={device.location}></input>
                                                            </td>
                                                            <td>IP Address:</td>
                                                            <td>
                                                                <input id="ip" name="ip" onChange={(event) => {
                                                                    onChangeHandler(deviceType, 'ip_address', event.target.value)
                                                                }} defaultValue={device.ip_address}></input>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : <></>}
                                        </div>
                                    </div>
                                    <div className="modelFooter">
                                        <button className="action-item" onClick={() => {
                                            updateHandler(selectedDevice.id, deviceType);
                                        }}>Update</button>
                                        <button className="action-item" onClick={() => {
                                            setDeleteDeviceConfirm(true);
                                        }}>Delete</button>
                                        {deviceDetails.type === "computer" ? 
                                        <button className="action-item" onClick={() => {
                                            setAssignedEmployee(employees[0]._id);
                                            setAssignNewEmployee(true);
                                            setDeviceDetails({ type: "computer", show: false, edit: false });
                                        }}>Assign Employee</button>
                                        : <></>}
                                        <button className="ahref" onClick={() => {
                                            setDeviceDetails({ type: deviceDetails.type, show: true, edit: false });
                                        }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>}

                    {deleteDeviceConfirm ?
                        <div>
                            <div className="model modelb">
                                <div className="modelConfirmTwo">
                                    <h2>Are you sure you want to delete {selectedDevice.name}?</h2>
                                </div>
                                <div className="modelFooter">
                                    <button className="action-item" onClick={() => {
                                        deleteHandler(selectedDevice.id, deviceType);
                                    }}>Confirm</button>
                                    <button className="ahref" onClick={() => {
                                        setDeleteDeviceConfirm(false);
                                    }}>Cancel</button>
                                </div>
                            </div>
                            
                        </div>
                        : <></>}

                    {deleteEmployeeConfirm ?
                        <div>
                            <div className="model modelb">
                                <div className="modelConfirmTwo">
                                    <h2>Are you sure you want to delete This Employee?</h2>
                                </div>
                                <div className="modelFooter">
                                    <button className="action-item" onClick={() => {
                                        deleteHandler("", "");
                                    }}>Confirm</button>
                                    <button className="ahref" onClick={() => {
                                        setDeleteEmployeeConfirm(false);
                                    }}>Cancel</button>
                                </div>
                            </div>

                        </div>
                        : <></>}

                    {assignNewEmployee ?
                        <div className="model modelb">
                            <div className="modelConfirm">
                                <h2>Assign Employee to {selectedDevice.name}</h2>
                                <div className="modelContainer">
                                    <div className="modelInput">{
                                        (employees.length > 0) ? <select onChange={(event) => {
                                            setAssignedEmployee(event.target.value);
                                        }}>{
                                                employees.map((e, i) => (
                                                    <option key={i} value={e._id} >{e.firstName + " " + e.lastName}</option>
                                                ))
                                            }</select>
                                            : <div>No Employees Available</div>
                                    }
                                    </div>
                                </div>
                                <div className="modelFooter">
                                    <button className="action-item" onClick={() => {
                                        assignEmployee(selectedDevice.id); setDeviceDetails({ type: "computer", show: false, edit: true });
                                    }}>
                                        Assign
                                    </button>
                                    <button className="ahref" onClick={() => {
                                        setAssignNewEmployee(false); setDeviceDetails({ type: "computer", show: false, edit: true });
                                    }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                        : <></>}

                </div>

            </div>
            
        </>
    );
}