import { useState, useEffect } from "react";
import "./../App.css";
import io from 'socket.io-client';
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import axios from "axios";
import { Link } from "react-router-dom";
import { NavLink, Outlet, matchPath, useLocation } from "react-router-dom";
import { flash } from "./flash";
const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config
const _socket = io(`https://${window.location.hostname}`);
export const Home = () => {
    const location = useLocation();
    /* Set Init Vars */
    const [init, setInit] = useState(false);
    const [ready, setReady] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [sites, setSites] = useState([] as any[]);
    const [selectedSite, setSelectedSite] = useState({ "id": 'null' });
    const [clients, setClients] = useState([] as any[]);
    const [selectedClient, setSelectedClient] = useState({ "id": 'null' });
    const [createNewSite, setCreateNewSite] = useState(false);
    const [createNewClient, setCreateNewClient] = useState(false);
    const [createNewEmployee, setCreateNewEmployee] = useState(false);
    const [background, setBackground] = useState(-1);
    const [selectedSiteConfirm, setSelectedSiteConfirm] = useState({ "id": null });
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([] as any[]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [sitesOD, setSitesOD] = useState("");
    const [clientsOD, setClientsOD] = useState("");
    const [domain, setDomain] = useState(searchParams.get('d'));
    const [rememberMe, setRememberMe] = useState(false);
    const [openOneDrive, setOpenOneDrive] = useState(true);
    const [openSites, setOpenSites] = useState(true);
    const [openClients, setOpenClients] = useState(true);
    const [organizationName, setOrganizationName] = useState('');
    const [permissions, setPermissions] = useState({
        organization: "",
        showBackups: false,
        showDirectory: false,
        showInventory: false,
        showEvents: false,
        showHome: false,
        showRecycleBin: false,
        showPasswords: false,
        showReport: false,
        showSites: false,
        showTeams: false,
        user: ""
    });
    /* End Set Init Vars */
    /* Use Effect */
    const [socket, setSocket] = useState(_socket);
    const [socketAlert, setSocketAlert] = useState("");
    const [isSocketAlert, setIsSocketAlert] = useState(false);
    useEffect(() => {
        if (initLoading) {
            document.title = "tjmicro";
        } else if (!ready) {
            document.title = "tjmicro - log in";
        } else {
            document.title = "tjmicro - portal";
        }
    }, [ready, initLoading]);
    // Used to connect to the Socket.io Backend
    useEffect(() => {
        const onConnect = () => {
            console.log('Connected');
        }
        const onDisconnect = () => {
            console.log('Disconnected');
        }
        const onFooEvent = (value: any) => {
            const controller = new AbortController();
            callNotificationsAPI('', controller);
            setSocketAlert(value);
            setIsSocketAlert(true);
            setTimeout(() => {
                setIsSocketAlert(false);
            }, 6000);
        }
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('foo', onFooEvent);
        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('foo', onFooEvent);
        };
    }, []); // Runs Once
    const _sites = JSON.stringify(sites);
    useEffect(() => {
        if (!(sites && sites.length > 0)) {
            return;
        }
        const ss = localStorage.getItem("selectedSite");
        setSelectedSiteC((ss && ss !== 'null') ? JSON.parse(ss) : sites[0]);
    }, [_sites, sites]);
    const _clients = JSON.stringify(clients);
    useEffect(() => {
        if (!(clients && clients.length > 0)) {
            return;
        }
        setSelectedClient(clients[0]);
    }, [_clients, clients]);
    /* End Use Effect */
    /* Axios States */
    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');
    /* End Axios States */
    const setSelectedSiteC = (val: any) => {
        const sss = JSON.stringify(val);
        localStorage.setItem("selectedSite", sss);
        setSelectedSite(val);
    }
    const callSiteAPI = (token: string) => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/sites", {})
                .then((response) => {
                    /* Start Check for Errors */
                    const ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */

                    const f: any[] = response.data.sites;
                    setSites([...f]);
                })
                .catch((error) => { })
                .finally(() => { console.log(sites)});
        }, 0)
    }
    const callOpenNotificationsAPI = (token: string) => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/opennotifications", {})
                .then((response) => {
                    /* Start Check for Errors */
                    const ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                })
                .catch((error) => { })
                .finally(() => { });
        }, 0)
    }
    const callNotificationsAPI = (token: string, controller: AbortController) => {
        setTimeout(() => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/notifications", {})
                .then((response) => {
                    /* Start Check for Errors */
                    const ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    const f: any[] = response.data;
                    setNotifications([...f].reverse());
                    setNotificationsCount(f.filter((x) => { return !x.read }).length);
                })
                .catch((error) => { })
                .finally(() => { });
        }, 0)
    }
    const callCreateSiteAPI = (token: string) => {
        const name = document.getElementById("createName") as HTMLInputElement | null;
        const displayName = document.getElementById("createDisplayName") as HTMLInputElement | null;
        const description = document.getElementById("createDescription") as HTMLInputElement | null;
        const instance = axios.create({
            withCredentials: true
        });
        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });
        instance
            .post(domainurl + "/createSite", { displayName: displayName!.value, name: name!.value, description: description!.value })
            .then((response) => {
                /* Start Check for Errors */
                const ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                callSiteAPI('true');
                setCreateNewSite(false);
            })
            .catch((error) => { })
            .finally(() => { });
    }
    const callDeleteSiteAPI = (token: string, site: any) => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/deleteSite", { siteid: site.id })
                .then((response) => {
                    /* Start Check for Errors */
                    const ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    callSiteAPI('true');
                })
                .catch((error) => { })
                .finally(() => { });
        }, 0)
    }
    const callCheckAuth = (controller: AbortController) => {
        const instance = axios.create({
            signal: controller.signal,
            withCredentials: true
        });
        instance
            .get(domainurl + "/checkAuthState", {})
            .then((response) => {
                if (response.data.organizationName !== undefined) {
                    localStorage.setItem("token", 'true');
                    setReady(true);
                    setOrganizationName(response.data.organizationName);
                    setPermissions(response.data.permissions);
                    callSiteAPI('true');
                } else {
                    if (domain && domain!.length > 0) {
                        callLoginAPI();
                    }
                }
                setInitLoading(false);
            })
            .catch((error) => {
                setInitLoading(false);
                if (domain && domain!.length > 0) {
                    setTimeout(() => {
                        callLoginAPI();
                    }, 1000);
                }
            })
            .finally(() => { });
    }
    const callSettingsAPI = (token: string, controller: AbortController) => {
        setTimeout(() => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/fetchSettings")
                .then((response) => {
                    /* Start Check for Errors */
                    const ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Start Check for Errors */

                    setBackground(response.data.background);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0);
    }
    const callLoginAPI = () => {
        const mainhomeorganizations_value = document.getElementById("mainhomeorganizations_input") as HTMLInputElement | null;
        window.location.href = domainurl + "/getCallbackURL?domain=" + mainhomeorganizations_value!.value + "&rb=" + rememberMe;
    }
    const openWindow = () => {
        window.location.href = "mailto:support@tjmicro.com";
    }
    const toggleNotifications = () => {
        const controller = new AbortController();
        if (!showNotifications) {
            callOpenNotificationsAPI('');
            callNotificationsAPI('', controller);
        }
        setShowNotifications(!showNotifications);
    }
    const logout = () => {
        const instance = axios.create({
            withCredentials: true
        });
        instance
            .post(domainurl + "/logout", {})
            .then((response) => {
                localStorage.setItem("token", null!);
                setInitLoading(false);
                setReady(false);
            })
            .catch((error) => {
                setInitLoading(false);
            })
            .finally(() => { });
    }
    const aaa = () => {
        setSelectedSiteC({ id: 'onedrive'! });
    }
    const token = localStorage.getItem("token");
    // Main Function, gets called once.
    useEffect(() => {
        const controller = new AbortController();
        let ignore = false;
        let t1: ReturnType<typeof setTimeout>;
        let t2: ReturnType<typeof setTimeout>;
        if (!ignore) {
            if (!ready && !init) {
                setInit(true);
                setInitLoading(true!);
                t1 = setTimeout(() => {
                    callCheckAuth(controller);
                    callSettingsAPI('', controller);
                    callClientAPI('token', controller);
                    setSelectedClient(clients[0]._id);
                }, 1000);
                t2 = setTimeout(() => {
                    callNotificationsAPI('', controller);
                }, 3000);
            } else if (!(token && token !== null && token !== "null") &&
                (window.location.pathname !== '/') &&
                (window.location.pathname !== '/about')) {
                window.location.href = '/';
            }
        }
        return () => {
            controller.abort();
            clearTimeout(t1);
            clearTimeout(t2);
            ignore = true;
        }
    }, []);
    const callClientAPI = (token: string, controller: AbortController) => {
        const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/clients", {})
                .then((response) => {
                    /* Start Check for Errors */
                    const ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    const f: any[] = response.data.clients;
                    //console.log(f);
                    setClients([...f]);
                })
                .catch((error) => { })
                .finally(() => { console.log(clients); });
    }
    let getClient: any = async (token: string, clientid: string) => {
        return new Promise( (resolve, reject) => {
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/client", { id: clientid })
                .then((response) => {
                    /* Start Check for Errors */
                    const ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    const f = response.data.client._id;
                    //console.log(f);
                    resolve(f);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {  });
        })
    }
    const callCreateClientAPI = (token: string) => {
        const name = document.getElementById("createName") as HTMLInputElement | null;
        const instance = axios.create({
            withCredentials: true
        });
        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });
        instance
            .post(domainurl + "/createClient", { name: name!.value })
            .then((response) => {
                /* Start Check for Errors */
                const ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */
                const controller = new AbortController();
                callClientAPI('true', controller);
                setCreateNewClient(false);
            })
            .catch((error) => { })
            .finally(() => { });
    }
    let callCreateEmployeeAPI = async (token: string) => {
        try {
            const firstName = document.getElementById("firstName") as HTMLInputElement | null;
            const lastName = document.getElementById("lastName") as HTMLInputElement | null;
            const clientRef: any = await getClient(token, selectedClient.id) as any;
            //console.log(client);
            const instance = axios.create({
                withCredentials: true
            });
            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });
            instance
                .post(domainurl + "/createEmployee", { firstName: firstName!.value, lastName: lastName!.value, client: clientRef })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                    
                    setCreateNewEmployee(false);
                })
                .catch((error) => { })
                .finally(() => { });
        } catch (err){
            console.log(err);
        }
    }
    let saveSelectedClient = async (id:string) => {
        const clientRef: any = await getClient(token, selectedClient.id) as any;

        localStorage.setItem("selectedClient", clientRef);
    }

    /**
     * Get / Set Callback Methods
     */
    const setSitesODH = (event : any) => {
        setSitesOD(event.target.value);
    }
    const callLoginAPIH = () => {
        callLoginAPI();
    }
    const clientC = () => {
        const controller = new AbortController();
        callClientAPI('true', controller);
    }
    /**
     * End Get / Set Callback Methods
     */

    const setRememberMeWrapper = () => {
        setRememberMe(!rememberMe);
    }


    let cc = {
        sites: sites, selectedSite: selectedSite, clients: clients,
        selectedClient: selectedClient, organizationName: organizationName,
        permissions: permissions
    }
    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            {initLoading ? <div className="loadingMax"><img src="loading.gif" alt="Loading..." /></div> : <div>{!ready ?
                <>
                    <div className="html"></div>
                    <div className="App">
                        <main>
                            <div className="mainhome">
                                <h2>TJMicro</h2>
                                <p><strong>Sharepoint Solutions Secured Portal</strong><br /></p>
                                <div className="mainhomeorganizations">
                                    <input id="mainhomeorganizations_input" type="text" placeholder="Domain Name" onChange={event => setDomain(event.target.value)} value={domain!} />
                                </div>
                                <div>
                                    <p><button onClick={callLoginAPIH}>Log In</button></p>
                                </div>
                                <p><input id="mainhomeorganizations_checkbox" type="checkbox" onChange={event => setRememberMeWrapper()} value={(+ rememberMe)} /> <label htmlFor="mainhomeorganizations_checkbox">Remember Me</label></p>
                            </div>
                        </main>
                    </div>
                    <p className="forgotyourpassword">
                        <nav>
                            <Link to="/about">Forgot Domain?</Link>
                        </nav>
                    </p>
                </>
                : <>
                    <div className="contactUs">
                        <span onClick={() => { openWindow(); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="currentColor" className="support bi bi-chat-text-fill" viewBox="0 0 32 32">
                                <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM4.5 5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7zm0 2.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7zm0 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4z" />
                            </svg>
                        </span>
                    </div>
                    <div className={(showNotifications || isSocketAlert) ? 'TJNotificationsPop selected' : 'TJNotificationsPop'}>
                        <h3>Notifications</h3>
                        <div className="TJNotificationW">{
                            notifications.map((notification) => (
                                <div className="TJNotification">
                                    <div><h4>{notification.type}</h4></div>
                                    <div>{notification.description}</div>
                                    <br />
                                    <div>{moment(notification.date).format('l LT')}</div>
                                </div>
                            ))
                        }</div>
                    </div>
                    <header>
                        <div className="progressBar">
                            <div className="track" style={{ width: 0 + '%' }}></div>
                        </div>
                        <span className="TJTitleSquare">
                            <a target="_blank" rel="noreferrer" className="logolink" href="https://www.tjmicro.com">
                                <img alt="TJMicro Logo" className="logo" src="https://tjmicro.com/wp-content/uploads/TJ-MICRO-LOGO-239x300.png" />
                            </a>
                            <span className="logoTitle">TJMICRO</span>
                        </span>
                        <div className="TJTitleSquare-all">
                            <div className="TJTitle"></div>
                        </div>
                        <div className="TJTitleNotification">
                            <span onClick={() => { toggleNotifications(); }}>
                                <span>{(notificationsCount > 0) ? notificationsCount : ''}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                                </svg>
                            </span>
                        </div>
                        <div className="TJTitleNotification2">
                            <div onClick={logout}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
                                    <path d="M7.5 1v7h1V1h-1z" />
                                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                                </svg>
                            </div>
                        </div>
                    </header>
                    <div className={'htmlall htmlall' + background}></div>
                    <div className="LeftBar">
                        <div className="LeftBarC">
                            <nav>
                                <div className={(permissions.showHome) ? "LeftBarItem" : "LeftBarItem"}>
                                    <NavLink className={({ isActive }) => (!!matchPath(location.pathname, "/") && isActive ? 'active' : '')} to="/">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
                                            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                                        </svg><span>Home</span></span>
                                    </NavLink>
                                </div>
                                <div className={(permissions.showSites) ? "LeftBarItem" : "hide"}>
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/sites">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-diagram-3" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                                        </svg><span>Sites</span></span>
                                    </NavLink>
                                </div>
                                <div className={(permissions.showDirectory) ? "LeftBarItem" : "hide"}>
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/directory">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder" viewBox="0 0 16 16">
                                            <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z" />
                                        </svg><span>Directory</span></span>
                                    </NavLink>
                                </div>
                                <div className={(permissions.showInventory) ? "LeftBarItem" : "hide"}>
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/inventory">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line>
                                        </svg><span>Inventory</span></span>
                                    </NavLink>
                                </div>
                                <div className={(permissions.showReport) ? "LeftBarItem" : "hide"}>
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/report">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-newspaper" viewBox="0 0 16 16">
                                            <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z" />
                                            <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z" />
                                        </svg><span>Report</span></span>
                                    </NavLink>
                                </div>
                                <div className={(permissions.showRecycleBin) ? "LeftBarItem" : "hide"}>
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/recyclebin">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-recycle" viewBox="0 0 16 16">
                                            <path d="M9.302 1.256a1.5 1.5 0 0 0-2.604 0l-1.704 2.98a.5.5 0 0 0 .869.497l1.703-2.981a.5.5 0 0 1 .868 0l2.54 4.444-1.256-.337a.5.5 0 1 0-.26.966l2.415.647a.5.5 0 0 0 .613-.353l.647-2.415a.5.5 0 1 0-.966-.259l-.333 1.242-2.532-4.431zM2.973 7.773l-1.255.337a.5.5 0 1 1-.26-.966l2.416-.647a.5.5 0 0 1 .612.353l.647 2.415a.5.5 0 0 1-.966.259l-.333-1.242-2.545 4.454a.5.5 0 0 0 .434.748H5a.5.5 0 0 1 0 1H1.723A1.5 1.5 0 0 1 .421 12.24l2.552-4.467zm10.89 1.463a.5.5 0 1 0-.868.496l1.716 3.004a.5.5 0 0 1-.434.748h-5.57l.647-.646a.5.5 0 1 0-.708-.707l-1.5 1.5a.498.498 0 0 0 0 .707l1.5 1.5a.5.5 0 1 0 .708-.707l-.647-.647h5.57a1.5 1.5 0 0 0 1.302-2.244l-1.716-3.004z" />
                                        </svg><span>Recycle Bin</span></span>
                                    </NavLink>
                                </div>
                                <div className={(permissions.showTeams) ? "LeftBarItem" : "hide"}>
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/team">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                        </svg><span>Team</span></span>
                                    </NavLink>
                                </div>
                                <div className={(permissions.showPasswords) ? "LeftBarItem" : "hide"}>
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/passwords">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                            <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                                            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                        </svg><span>Passwords</span></span>
                                    </NavLink>
                                </div>
                                <div className={(permissions.showBackups) ? "LeftBarItem" : "hide"}>
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/backups">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-drizzle" viewBox="0 0 16 16">
                                            <path d="M4.158 12.025a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm-3.5 1.5a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 1 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm.747-8.498a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 11H13a3 3 0 0 0 .405-5.973zM8.5 2a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4.002 4.002 0 0 1 8.5 2z" />
                                        </svg><span>Backups</span></span>
                                    </NavLink>
                                </div>
                                <div className={(permissions.showEvents) ? "LeftBarItem" : "hide"}>
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/events">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar2-heart" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5ZM1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3Zm2 .5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5H3Zm5 4.493c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
                                        </svg><span>Events</span></span>
                                    </NavLink>
                                </div>
                                <div className="LeftBarItem">
                                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/settings">
                                        <span className="LeftBarItemIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                        </svg><span>User Settings</span></span>
                                    </NavLink>
                                </div>
                            </nav>
                        </div>
                    </div>
                    {(!!!matchPath(location.pathname, "/") && !!!matchPath(location.pathname, "/sites") && !!!matchPath(location.pathname, "/backups") && !!!matchPath(location.pathname, "/settings") && !!!matchPath(location.pathname, "/events") && !!!matchPath(location.pathname, "/team") && !!!matchPath(location.pathname, "/permissions") && !!!matchPath(location.pathname, "/sitepermissions") && !!!matchPath(location.pathname, "/inventory")) ?
                        <div className="LeftBarSites">
                            <input onChange={setSitesODH} className="SearchBarSitesOD" type="text" placeholder="Search ..." />
                            <div className="LeftBarSites-Header">
                                <div onClick={() => { setOpenSites(!openSites) }}><span>Sites</span><span>{(openSites) ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg>}</span></div>
                                <span className="LeftBarSites-Refresh" onClick={() => { callSiteAPI('true') }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                                        <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                                        <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                                    </svg>
                                </span>
                            </div>
                            <span>{(openSites) ?
                                <div>{
                                    (sites.filter((_s) => {
                                        return (!sitesOD || sitesOD === '') || (sitesOD && _s!.displayName.toLowerCase().indexOf(sitesOD.toLowerCase()) > -1)
                                    }).length > 0) ? sites.filter((_s) => {
                                        return (!sitesOD || sitesOD === '') || (sitesOD && _s!.displayName.toLowerCase().indexOf(sitesOD.toLowerCase()) > -1)
                                    }).map((site, i) => (
                                        <div key={i} onClick={() => { setSelectedSiteC(site); saveSelectedClient(""); }} className={(selectedSite.id === site.id) ? "SiteDisplayName selected" : "SiteDisplayName"}><span>{site!.displayName}</span>
                                            <span>{(site!.displayName !== "Communication site") ? <span className="bi-trash2" onClick={(e) => { setShowDeleteConfirm(true); setSelectedSiteConfirm(site); e.stopPropagation(); }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </span> : <></>}</span>
                                        </div>
                                    )) : <div>No Sites Available</div>
                                }</div> : <></>
                            }</span>
                            <div>
                                <div className="LeftBarSites-Header">
                                    <div><span onClick={() => { setOpenOneDrive(!openOneDrive) }}><span>OneDrive</span><span>{(openOneDrive) ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>}</span></span></div>
                                </div>
                                <span>{(openOneDrive) ?
                                    <div>
                                        <div onClick={() => { aaa(); }} className={(selectedSite.id === 'onedrive') ? "SiteDisplayName selected" : "SiteDisplayName"}>My OneDrive</div>
                                    </div> : <></>
                                }</span>
                            </div>
                            <div>
                                <button onClick={() => { setCreateNewSite(true); }}>
                                    <span>Create New Site</span>
                                </button>
                            </div>
                            {createNewSite ?
                                <div className="model modelb">
                                    <div className="modelConfirm">
                                        <h2>New Community Site</h2>
                                        <div className="modelContainer">
                                            <div className="modelInput">
                                                <input id="createDisplayName" type="text" placeholder="Display Name" />
                                                <input id="createName" type="text" placeholder="Name" />
                                            </div>
                                            <div className="modelInput">
                                                <textarea id="createDescription" placeholder="Description"></textarea>
                                            </div>
                                        </div>
                                        <div className="modelFooter">
                                            <button className="action-item" onClick={() => { callCreateSiteAPI('token'); }}>Create</button>
                                            <button className="ahref" onClick={() => { setCreateNewSite(false); }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                : <></>}
                            {showDeleteConfirm ?
                                <div className="model modelb">
                                    <div className="modelConfirm">
                                        Are you sure you want to delete item?
                                        <button className="delete-item" onClick={() => { callDeleteSiteAPI('token', selectedSiteConfirm); setShowDeleteConfirm(false); }}>Delete</button>
                                        <button className="ahref" onClick={() => { setSelectedSiteConfirm({ 'id': null }); setShowDeleteConfirm(false); }}>Cancel</button>
                                    </div>
                                </div>
                                : <></>}
                        </div> : <></>}
                    
                    {(matchPath(location.pathname, "/inventory")) ?
                        <div className="LeftBarSites">
                            <input onChange={(event) => { setClientsOD(event.target.value); }} className="SearchBarSitesOD" type="text" placeholder="Search ..." />
                            <div className="LeftBarSites-Header">
                                <div onClick={() => { setOpenClients(!openClients) }}>
                                    <span>Clients</span>
                                    <span>{(openClients) ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>}</span>
                                </div>
                                <span className="LeftBarSites-Refresh" onClick={clientC}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                                        <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                                        <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                                    </svg>
                                </span>
                            </div>
                            <span>{(openClients) ?
                                <div>{ 
                                    (clients.filter((_c) => {
                                        return (!clientsOD || clientsOD === '') || (clientsOD && _c!.name.toLowerCase().indexOf(clientsOD.toLowerCase()) > -1)
                                    }).length > 0) ? clients.filter((_c) => {
                                        return (!clientsOD || clientsOD === '') || (clientsOD && _c!.name.toLowerCase().indexOf(clientsOD.toLowerCase()) > -1)
                                    }).map((clientele, i) => (
                                        <div key={i} onClick={() => { setSelectedClient({ id: clientele._id }); }} className={(selectedClient.id === clientele._id) ? "SiteDisplayName selected" : "SiteDisplayName"}>
                                            <span>{clientele!.name}</span>
                                            <span>
                                                <span className="bi-trash2" onClick={() => { setSelectedClient({ id: clientele._id }); setCreateNewEmployee(true); }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        className="bi bi-trash" viewBox="0 0 24 24" fill="currentColor"
                                                        stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="8.5" cy="7" r="4"></circle>
                                                        <line x1="20" y1="8" x2="20" y2="14"></line>
                                                        <line x1="23" y1="11" x2="17" y2="11"></line>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                    )) : <div>No Clients Available</div>
                                }</div> : <></>}
                            </span>
                            <div>
                                <button onClick={() => { setCreateNewClient(true); }}>
                                    <span>Create New Client</span>
                                </button>
                            </div>
                            {createNewClient ?
                                <div className="model modelb">
                                    <div className="modelConfirm">
                                        <h2>Add New Client</h2>
                                        <div className="modelContainer">
                                            <div className="modelInput">
                                                <input id="createName" type="text" placeholder="Name" />
                                            </div>
                                        </div>
                                        <div className="modelFooter">
                                            <button className="action-item" onClick={() => { callCreateClientAPI('token'); }}>Create</button>
                                            <button className="ahref" onClick={() => { setCreateNewClient(false); }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                : <></>}
                            {createNewEmployee ?
                                <div className="model modelb">
                                    <div className="modelConfirm">
                                        <h2>Add New Employee</h2>
                                        <div className="modelContainer">
                                            <div className="modelInput">
                                                <input id="firstName" type="text" placeholder="First Name" />
                                                <input id="lastName" type="text" placeholder="Last Name" />
                                            </div>
                                        </div>
                                        <div className="modelFooter">
                                            <button className="action-item" onClick={() => { callCreateEmployeeAPI('token'); }}>Create</button>
                                            <button className="ahref" onClick={() => { setCreateNewEmployee(false); }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                : <></>}
                        </div> : <></>}
                    <div><Outlet context={{ ...cc }} /></div>
                </>}
            </div>}
        </>
    );
}