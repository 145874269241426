import "./App.css";
import { Routes, Route, Navigate, BrowserRouter as Router } from "react-router-dom";
import * as Pages from "./pages/import"
let About = () => Pages.About();
let Admin = () => Pages.Admin();
let Backup = () => Pages.Backup();
let Directory = () => Pages.Directory();
let Events = () => Pages.Events();
let Home = () => Pages.Home();
let Inventory = () => Pages.Inventory();
let Recycle = () => Pages.Recycle();
let Report = () => Pages.Report();
let Setting = () => Pages.Setting();
let Site = () => Pages.Site();
let Passwords = () => Pages.Passwords();
let Team = () => Pages.Team();
let TJMicroAdmin = () => Pages.TJMicroAdmin();
let Welcome = () => Pages.Welcome();
let App = () => {
  return (
    <div className="SharePoint">
      <div className="App1">
        <Router>
          <Routes>
            <Route path="/" element={ <Home /> }>
              <Route index element={ <Welcome /> }></Route>
              <Route path="sites" element={<Site />}></Route>
              <Route path="directory" element={ <Directory /> }></Route>
              <Route path="inventory" element={<Inventory />}></Route>
              <Route path="team" element={ <Team /> }></Route>
              <Route path="passwords" element={ <Passwords /> }></Route>
              <Route path="recyclebin" element={<Recycle />}></Route>
              <Route path="report" element={ <Report /> }></Route>
              <Route path="backups" element={ <Backup /> }></Route>
              <Route path="events" element={ <Events /> }></Route>
              <Route path="settings" element={ <Setting /> }></Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
            <Route path="about" element={ <About /> } />
            <Route path="tjmicroadmin" element={ <TJMicroAdmin /> } />
            <Route path="admin" element={ <Admin /> } />
          </Routes>
        </Router>
      </div>
    </div>
  );
}
export default App;