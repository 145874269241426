import { useState, useEffect } from "react";
import "./../App.css";

import axios from "axios";
import { useOutletContext } from "react-router-dom";

import { flash } from "./flash";

const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config

export let Setting = () => {
    const outlet = useOutletContext() as any;
    const selectedSite = outlet.selectedSite;
    const sites = outlet.sites;

    const [background, setBackground] = useState(0);

    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');

    let callSettingsAPI = (token: string, controller: AbortController) => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/fetchSettings")
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Start Check for Errors */

                    setBackground(response.data.background);
                    setLoading2(false);
                })
                .catch((error) => {
                })
                .finally(() => { });
    }

    let callSaveSettingsAPI = (token: string) => {
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/saveSettings", { background: background })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */

                    // const f: any[] = response.data.sites;
                    // setSites([...f]);

                    window.location.reload();
                })
                .catch((error) => { })
                .finally(() => { });
    }

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        setLoading2(true);
        callSettingsAPI("false", controller);
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            <div className="three three-report">
                <div className="scroll2">
                    <div className="report-all">
                        <div className="splash">Settings</div>
                    </div>
                    <strong>
                        <span>User Settings</span>
                        <div>
                            Update Background Image:
                            <div className="backgroundImgsTinyContainer">
                                <img onClick={() => { setBackground(0); }} className={(background === 0) ? 'backgroundImgTiny selected' : 'backgroundImgTiny'} src="https://th.bing.com/th/id/R.4be5d55e33f380dafa162ed39570da0e?rik=ROhEFOaViX18pQ&pid=ImgRaw&r=0" />
                                <img onClick={() => { setBackground(1); }} className={(background === 1) ? 'backgroundImgTiny selected' : 'backgroundImgTiny'} src="https://th.bing.com/th/id/R.2d9fb6d88ed78c7da245998c3fef240c?rik=6yIVQd2X8PjSEg&riu=http%3a%2f%2fwww.wallpapers13.com%2fwp-content%2fuploads%2f2016%2f01%2fSunset-Background-Images-Hd-Sunset-background-images-hd-07329.jpg&ehk=7J99b98IPCvu0KnLxFH6gC%2bV%2b%2fcnD3GpSnjHO%2fJcNWE%3d&risl=&pid=ImgRaw&r=0" />
                                <img onClick={() => { setBackground(2); }} className={(background === 2) ? 'backgroundImgTiny selected' : 'backgroundImgTiny'} src="https://th.bing.com/th/id/R.bb98ddd886d1c58fb65dba8f602e0002?rik=wDMpyL9ulCFliQ&pid=ImgRaw&r=0" />
                                <img onClick={() => { setBackground(3); }} className={(background === 3) ? 'backgroundImgTiny selected' : 'backgroundImgTiny'} src="https://th.bing.com/th/id/R.b9cce58cb104108206bfb8fe6c7f7a2e?rik=Gg6DLKTJfg1JPg&riu=http%3a%2f%2fwww.wallpapers13.com%2fwp-content%2fuploads%2f2016%2f01%2fWaterfall-rocks-forest-red-leaves-background-HD-2560X1600-1680x1050.jpg&ehk=v5xJat%2bOI6ZHRsd1v9hcgA8ImQ83z92a6nkp49Zy0kY%3d&risl=&pid=ImgRaw&r=0" />
                            </div>
                        </div>
                        <div>
                            <button onClick={() => { callSaveSettingsAPI('') }}>Save</button>
                        </div>
                    </strong>
                    <footer>Settings</footer>
                </div>
            </div>
        </>
    )
}