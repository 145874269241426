import { useState, useEffect } from "react";
import "./../App.css";

import axios from "axios";
import { useOutletContext } from "react-router-dom";

import { flash } from "./flash";

const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config

export let Site = () => {
    const outlet = useOutletContext() as any;
    const selectedSite = outlet.selectedSite;
    const sites = outlet.sites;

    const [query, setQuery] = useState("");

    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');

    const [createNewSite, setCreateNewSite] = useState(false);

    let callCreateSiteAPI = (token: string) => {
        const name = document.getElementById("createName") as HTMLInputElement | null;
        const displayName = document.getElementById("createDisplayName") as HTMLInputElement | null;
        const description = document.getElementById("createDescription") as HTMLInputElement | null;

        const instance = axios.create({
            withCredentials: true
        });

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            config.headers!.Authorization = token ? `Bearer ${token}` : "";
            return config;
        });

        instance
            .post(domainurl + "/createSite", { displayName: displayName!.value, name: name!.value, description: description!.value })
            .then((response) => {
                /* Start Check for Errors */
                let ttoken = localStorage.getItem("token");
                if (!ttoken || ttoken === 'null') {
                    return;
                }
                if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                /* End Check for Errors */

                refresh();
                setCreateNewSite(false);
            })
            .catch((error) => { })
            .finally(() => { });
    }

    let refresh = () => {
        // callMeAPI("true", true);
    }

    return (
        <>{
            flashError
                ? <div className="flash flash-error">{flashMessage}</div>
                : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            <div>
                <div className="headerFolders">
                    <div className="splash">Sites</div>
                    <div onClick={refresh} className="refresh">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                            <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                            <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                        </svg>
                    </div>
                </div>
                <div>{createNewSite ?
                    <div className="model7 model">
                        <div className="modelConfirm">
                            <h2>New Community Site</h2>
                            <div className="modelContainer">
                                <div className="modelInput">
                                    <input id="createDisplayName" type="text" placeholder="Display Name" />
                                    <input id="createName" type="text" placeholder="Name" />
                                </div>
                                <div className="modelInput">
                                    <textarea id="createDescription" placeholder="Description"></textarea>
                                </div>
                            </div>
                            <div className="modelFooter">
                                <button className="action-item" onClick={() => { callCreateSiteAPI('token'); }}>Create</button>
                                <button className="ahref" onClick={() => { setCreateNewSite(false); }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    : <></>}</div>
                <div><span><input onChange={event => setQuery(event.target.value)} className="SearchBarSites" type="text" placeholder="Search Sites ..." /></span>
                    <div className="siteContainer">
                    <>{
                    ((sites && sites.length > 0) ? sites.filter((ss: any) => {
                        if (query === '') {
                            return ss;
                        } else if (ss.displayName.toLowerCase().includes(query.toLowerCase())) {
                            return ss;
                        }
                    }).map((site: any, i: any) => (
                        <div className="siteItem" key={i} ><span>{site!.displayName}</span></div>
                    )) : <></>)
                }</><div className="siteItem" onClick={() => { setCreateNewSite(true); }}><span>+ New Site</span></div></div></div>
            </div>
        </>
    )
}

export let Passwords = () => {
    const outlet = useOutletContext() as any;
    const selectedSite = outlet.selectedSite;
    const sites = outlet.sites;

    const [passwords, setPasswords] = useState([] as any[]);
    const [perLimit, setPerLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);

    /* Flash Messages */
    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');
    /* End Flash Messages */

    const [query, setQuery] = useState("");

    /* Show Password Model Forms */
    const [showPasswordCreateConfirm, setShowPasswordCreateConfirm] = useState(false);
    const [showPasswordUpdateConfirm, setShowPasswordUpdateConfirm] = useState(false);
    const [showPasswordDeleteConfirm, setShowPasswordDeleteConfirm] = useState(false);
    /* End Show Password Model Forms */

    /* New Password Fields */
    const [url, setUrl] = useState("");
    const [description, setDescription] = useState("");
    const [value, setValue] = useState("");
    const [notepad, setNotepad] = useState("");
    /* End New Password Fields */

    /* Update Password Fields */
    const [updatePasswordId, setUpdatePasswordId] = useState('');
    const [urlUpdate, setUrlUpdate] = useState("");
    const [descriptionUpdate, setDescriptionUpdate] = useState("");
    const [valueUpdate, setValueUpdate] = useState("");
    const [notepadUpdate, setNotepadUpdate] = useState("");
    /* End Update Password Fields */

    /* Delete Password Fields */
    const [deletePasswordId, setDeletePasswordId] = useState('');
    /* End Delete Password Fields */

    const savePasswordAPI = (token : string) => {
        setTimeout(() => {
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/createPassword", { url: url, description: description, value: value, notepad: notepad })
                .then((response) => {
                    setTimeout(() => {
                        /* Start Check for Errors */
                        let ttoken = localStorage.getItem("token");
                        if (!ttoken || ttoken === 'null') {
                            return;
                        }
                        if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                        /* End Check for Errors */

                        refreshC();
                        setLoading2(false);
                    }, 0);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }

    const fetchPasswordsAPI = (token: string, controller: AbortController) => {
        setTimeout(() => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/fetchPasswords", { })
                .then((response) => {
                    setTimeout(() => {
                        /* Start Check for Errors */
                        let ttoken = localStorage.getItem("token");
                        if (!ttoken || ttoken === 'null') {
                            return;
                        }
                        if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                        /* End Check for Errors */

                        setPasswords(response.data.value);
                        //callMeAPI2(token);
                        setLoading2(false);
                    }, 0);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }

    const updatePasswordAPI = (_id: string, controller: AbortController) => {
        const token = '';
        setTimeout(() => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/updatePassword", {
                    "id": _id, "url": urlUpdate, "description": descriptionUpdate, "value": valueUpdate, "notepad": notepadUpdate
                })
                .then((response) => {
                    setTimeout(() => {
                        /* Start Check for Errors */
                        let ttoken = localStorage.getItem("token");
                        if (!ttoken || ttoken === 'null') {
                            return;
                        }
                        if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                        /* End Check for Errors */
                        refreshC();
                        setLoading2(false);
                    }, 0);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }

    const deletePasswordAPI = (_id: string, controller: AbortController) => {
        const token = '';
        setTimeout(() => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/deletePassword", { "id": _id })
                .then((response) => {
                    setTimeout(() => {
                        /* Start Check for Errors */
                        let ttoken = localStorage.getItem("token");
                        if (!ttoken || ttoken === 'null') {
                            return;
                        }
                        if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                        /* End Check for Errors */
                        refreshC();
                        setLoading2(false);
                    }, 0);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }

    const callGeneratePasswordAPI = (controller: AbortController) => {
        const token = '';
        setTimeout(() => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/generateUniquePassword", { })
                .then((response) => {
                    setValue("");
                    setValueUpdate("");
                    setTimeout(() => {
                        /* Start Check for Errors */
                        let ttoken = localStorage.getItem("token");
                        if (!ttoken || ttoken === 'null') {
                            return;
                        }
                        if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                        /* End Check for Errors */

                        const password = response.data.value;
                        setValue(password);
                        setValueUpdate(password);

                        function copyToClipboard() {
                            if ('clipboard' in navigator) {
                                navigator.clipboard.writeText(password)
                                    .then(() => {
                                        setFlashWarning(true);
                                        setFlashMessage("Password copied to clipboard");
                                        setTimeout(() => {
                                            setFlashWarning(false);
                                            setFlashMessage('');
                                        }, 5000);
                                    })
                                    .catch(err => {
                                        setFlashError(true);
                                        setFlashMessage("Unable to copy password to clipboard");
                                        setTimeout(() => {
                                            setFlashError(false);
                                            setFlashMessage('');
                                        }, 5000);
                                    });
                            }
                        }

                        // Usage: Call the function to copy a string to the clipboard
                        copyToClipboard();
                    }, 0);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0)
    }

    /* Helper Methods */
    const resetForm = () => {
        setUrl("");
        setDescription("");
        setValue("");
        setNotepad("");
        setUrlUpdate("");
        setDescriptionUpdate("");
        setValueUpdate("");
        setNotepadUpdate("");
    }
    const savePassword = () => () => {
        savePasswordAPI("");
        hidePasswordCreate();
        resetForm();
    }
    const updatePassword = (d: any) => (event: any) => {
        resetForm();
        setUpdatePasswordId(d._id);
        setUrlUpdate(d.url);
        setDescriptionUpdate(d.description);
        setValueUpdate(d.value);
        setNotepadUpdate(d.notepad);

        setShowPasswordUpdateConfirm(true);
    }
    const deletePassword = (_id: string) => (event: any) => {
        setDeletePasswordId(_id);
        setShowPasswordDeleteConfirm(true);
    }
    const callGeneratePassword = () => () => {
        const controller = new AbortController();
        callGeneratePasswordAPI(controller);
    }

    const updatePasswordConfirm = () => () => {
        const controller = new AbortController();
        updatePasswordAPI(updatePasswordId, controller);
        hidePasswordUpdate();
    }

    const hidePasswordCreate = () => {
        setDeletePasswordId("");
        setShowPasswordCreateConfirm(false);
    }
    const hidePasswordUpdate = () => {
        setDeletePasswordId("");
        setShowPasswordUpdateConfirm(false);
    }
    const hidePasswordDelete = () => {
        setDeletePasswordId("");
        setShowPasswordDeleteConfirm(false);
    }

    const hidePasswordCreateModel = () => () => {
        hidePasswordCreate();
    }
    const hidePasswordUpdateModel = () => () => {
        hidePasswordUpdate();
    }
    const hidePasswordDeleteModel = () => () => {
        hidePasswordDelete();
    }

    const deletePasswordConfirm = () => () => {
        const controller = new AbortController();
        deletePasswordAPI(deletePasswordId, controller);
        hidePasswordDelete();
    }
    const refreshC = () => {
        const controller = new AbortController();
        refresh(controller);
    }
    const refresh = (controller: AbortController) => {
        fetchPasswordsAPI("", controller);
    }
    const openNewPassword = () => () => {
        setValue("");
        setShowPasswordCreateConfirm(true);
    }
    /* Helper Methods */

    /* Loading Helper Methods */
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    /* End Loading Helper Methods */

    useEffect(() => {
        const controller = new AbortController();
        refresh(controller);
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            <div className="headerFolders">
                <div className="splash">Passwords</div>
                <div onClick={refreshC} className="refresh">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                        <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                        <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                    </svg>
                </div>
            </div>
            <div className="three three-report">
                <div className="scroll2">
                    <strong>{(loading2) ? <div className="">
                        <svg className="circlespinner" viewBox="0 0 50 50">
                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                        </svg>
                    </div> :
                        <div>
                            <button className="OpenNewPassword" onClick={ openNewPassword() }>Create New Password +</button>
                            <div className="hide">{(sites.length > 0) ?
                                <select id="permissionsSitesDD">{sites.map((site: any) => (
                                    <option value={site.id}>{site.displayName}</option>
                                ))}</select>
                                : <div>No Sites Available</div>
                            }</div>
                            <div className="Sites-Header Passwords-Header">
                                <div>
                                    <div className="Sites-Cell">URL</div>
                                    <div className="Sites-Cell">Description</div>
                                    <div className="Sites-Cell">Password</div>
                                    <div className="Sites-Cell Sites-Cell2">Notepad</div>
                                </div>
                            </div>
                            <div className="show passwords-container Sites-Main">{
                                (passwords.filter((ss: any) => {
                                    if (query === '') {
                                        return ss;
                                    } else if (ss.displayName.toLowerCase().includes(query.toLowerCase())) {
                                        return ss;
                                    }
                                }).length > 0 ?
                                    passwords.filter((ss: any) => {
                                        if (query === '') {
                                            return ss;
                                        } else if (ss.displayName.toLowerCase().includes(query.toLowerCase())) {
                                            return ss;
                                        }
                                    }).slice((pageNumber - 1) * perLimit, pageNumber * perLimit).map((d, i) => (
                                        <>
                                            <div className="Sites-Container">
                                                <div className="Sites-Cell"><span className="url-small"><span>{d.url}</span></span></div>
                                                <span> </span>
                                                <div className="Sites-Cell"><span>{d.description}</span></div>
                                                <span> </span>
                                                <div className="Sites-Cell"><span>{d.value}</span></div>
                                                <span> </span>
                                                <div className="Sites-Cell Sites-Cell2"><span>{d.notepad}</span></div>
                                                <div className="Sites-Cell" onClick={updatePassword(d)}>Update</div>
                                                <div className="Sites-Cell" onClick={deletePassword(d._id)}>Delete</div>
                                            </div>
                                        </>
                                    )) : <div className="Sites-Container2">No Passwords to Show</div>)
                            }</div>
                            {
                                showPasswordCreateConfirm ?
                                    <div className="model22 modelbackup">
                                        <div className="modelConfirm modelConfirmThree bb mcenter">
                                            <div>
                                                <div>Create Password (Data is Encrypted before saving)</div>
                                                <div>
                                                    <label>URL: </label><input type="text" value={url} onChange={(event) => { setUrl(event.target.value) }} /><br />
                                                    <label>Description: </label><input type="text" value={description} onChange={(event) => { setDescription(event.target.value) }} /><br />
                                                    <label>Value: </label><input type="password" value={value} onChange={(event) => { setValue(event.target.value) }} /><div onClick={ callGeneratePassword() }>Generate</div><br />
                                                    <label>Notepad: </label><textarea value={notepad} onChange={(event) => { setNotepad(event.target.value) }}></textarea>
                                                </div>
                                                <div>
                                                    <button onClick={savePassword()}>Save</button>
                                                    <button className="ahref" onClick={hidePasswordCreateModel()}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                            {
                                showPasswordUpdateConfirm ?
                                    <div className="model22 modelbackup">
                                        <div className="modelConfirm modelConfirmThree bb mcenter">
                                            <div>
                                                <div>Update Password (Data is Encrypted before saving)</div>
                                                <div>
                                                    <label>URL: </label><input type="text" value={urlUpdate} onChange={(event) => { setUrlUpdate(event.target.value) }} /><br />
                                                    <label>Description: </label><input type="text" value={descriptionUpdate} onChange={(event) => { setDescriptionUpdate(event.target.value) }} /><br />
                                                    <label>Value: </label><input type="password" value={valueUpdate} onChange={(event) => { setValueUpdate(event.target.value) }} /><div onClick={callGeneratePassword()}>Generate</div><br />
                                                    <label>Notepad: </label><textarea value={notepadUpdate} onChange={(event) => { setNotepadUpdate(event.target.value) }}></textarea>
                                                </div>
                                                <div>
                                                    <button onClick={updatePasswordConfirm()}>Update</button>
                                                    <button className="ahref" onClick={hidePasswordUpdateModel()}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                            {
                                showPasswordDeleteConfirm ?
                                    <div className="model22 modelbackup">
                                        <div className="modelConfirm modelConfirmThree bb mcenter">
                                            <div>Are you sure you want to delete password?</div>
                                            <button onClick={ deletePasswordConfirm() }>Delete</button>
                                            <button className="ahref" onClick={ hidePasswordDeleteModel() }>Cancel</button>
                                        </div>
                                    </div> : <></>
                            }
                            <div className="paginate-scroll fs">
                                <div className="page pageNumberC">
                                    <span className="pageNumber" onClick={() => { setPageNumber(1) }}>&lt;</span><span>{
                                        [...Array(Math.ceil(passwords.filter((ss: any) => {
                                            if (query === '') {
                                                return ss;
                                            } else if (ss.displayName.toLowerCase().includes(query.toLowerCase())) {
                                                return ss;
                                            }
                                        }).length / perLimit) > 0 ? Math.ceil(passwords.filter((ss: any) => {
                                            if (query === '') {
                                                return ss;
                                            } else if (ss.displayName.toLowerCase().includes(query.toLowerCase())) {
                                                return ss;
                                            }
                                        }).length / perLimit) : 1)].map((x, i) => (
                                            <span className={(pageNumber === (i + 1)) ? 'pageNumber selected' : ((((pageNumber - (i + 1)) <= 3) && ((pageNumber - (i + 1)) >= -3)) ? 'pageNumber' : 'pageNumber hide')} onClick={() => { setPageNumber(i + 1) }}>{(i + 1)}</span>
                                        ))
                                    }</span><span className="pageNumber" onClick={() => {
                                        setPageNumber(Array(Math.ceil(passwords.filter((ss: any) => {
                                            if (query === '') {
                                                return ss;
                                            } else if (ss.displayName.toLowerCase().includes(query.toLowerCase())) {
                                                return ss;
                                            }
                                        }).length / perLimit) > 0 ? Math.ceil(passwords.filter((ss: any) => {
                                            if (query === '') {
                                                return ss;
                                            } else if (ss.displayName.toLowerCase().includes(query.toLowerCase())) {
                                                return ss;
                                            }
                                        }).length / perLimit) : 1).length)
                                    }}>&gt;</span>
                                </div>
                                <div className="pagelimit">
                                    <div className="pageperpage">Per Page Limit: </div>
                                    <select onChange={(e) => { setPerLimit(parseInt(e.target.value)); setPageNumber(1); }} className="pageperpage">
                                        <option value="20">20</option>
                                        <option value="40">40</option>
                                        <option value="60">60</option>
                                        <option value="80">80</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    }</strong>
                    <footer>Passwords</footer>
                </div>
            </div>
        </>
    )
}