export const flash = (response: any, setFlashError: any, setFlashWarning: any, setFlashMessage: any) => {
    if (!response || (!response.data && response.data !== '')) {
        setFlashError(true);
        setFlashMessage("Internal Server Error");
        setTimeout(() => {
            setFlashError(false);
            setFlashMessage('');
        }, 5000);
        return true;
    }
    if (response && response.data && response.data.status >= 400) {
        setFlashError(true);
        setFlashMessage(response.data.message);
        setTimeout(() => {
            setFlashError(false);
            setFlashMessage('');
        }, 5000);
        return true;
    }
    if (response && response.data && response.data.status >= 200 && response.data.status < 300) {
        setFlashWarning(true);
        setFlashMessage(response.data.message);
        setTimeout(() => {
            setFlashWarning(false);
            setFlashMessage('');
        }, 5000);
        return false;
    }
    return false;
}