import { useState, useEffect } from "react";
import "./../App.css";

import moment from 'moment'

import axios from "axios";
import { useOutletContext } from "react-router-dom";

import { flash } from "./flash";

const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config

export let Events = () => {
    const outlet = useOutletContext() as any;
    const selectedSite = outlet.selectedSite;
    const sites = outlet.sites;
    const organizationName = outlet.organizationName;

    const [findTrackingEvents, setFindTrackingEvents] = useState([] as any[]);
    const [perLimit, setPerLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);

    const [query, setQuery] = useState("");

    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');

    let callFindTrackingEventsAPI = (token: string, controller: AbortController) => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/findTrackingEvents")
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Start Check for Errors */

                    setFindTrackingEvents(response.data.reverse());
                    setLoading2(false);
                })
                .catch((error) => {
                })
                .finally(() => { });
    }

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const findTrackingEventsC = () => {
        const controller = new AbortController();
        callFindTrackingEventsAPI("false", controller);
    }

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        setLoading2(true);
        callFindTrackingEventsAPI("false", controller);
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            <div className="three three-report">
                <div className="scroll2">
                    <div className="report-all">
                        <div className="splash">Events</div>
                        <div onClick={findTrackingEventsC} className="refresh">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                                <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                                <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                            </svg>
                        </div>
                    </div>
                    <strong>
                        <div>
                            <div className="Sites">
                                <div className="Sites-Header">
                                    <div>
                                        <div className="Sites-Cell">
                                            Event Type
                                        </div>
                                        <div className="Sites-Cell Sites-Cell2">
                                            Event Description
                                        </div>
                                        <div className="Sites-Cell">
                                            Dates and Time
                                        </div>
                                        <div className="Sites-Cell">
                                            Site
                                        </div>
                                    </div>
                                </div>
                                <span className=""><input onChange={event => setQuery(event.target.value)} className="SearchBarSites EventSearch" type="text" placeholder="Search Events ..." /></span>
                                <div>{
                                    findTrackingEvents.filter((ss: any) => {
                                        if (query === '') {
                                            return ss;
                                        } else if ((ss.type).toLowerCase().includes(query.toLowerCase()) || (ss.description).toLowerCase().includes(query.toLowerCase())) {
                                            return ss;
                                        }
                                    }).slice((pageNumber - 1) * perLimit, pageNumber * perLimit).map((bb, i) => (
                                        <div key={i} className="Sites-Body">
                                            <div className="Sites-Cell">{bb.type}</div>
                                            <div className="Sites-Cell Sites-Cell2">{bb.description}</div>
                                            <div className="Sites-Cell">{moment(bb.date).format('l LT')}</div>
                                            <div className="Sites-Cell">{(bb.site !== "All") ? <a target="_blank" href={((bb.type.indexOf('OneDrive') > -1) ? "https://" + organizationName + "-my.sharepoint.com/" : bb.site)}>Site</a> : <span>All Sites</span>}</div>
                                        </div>
                                    ))
                                }</div>
                                <div className="paginate-scroll fs">
                                    <div className="page pageNumberC">
                                        <span className="pageNumber" onClick={() => { setPageNumber(1) }}>&lt;</span><span>{
                                            [...Array(Math.ceil(findTrackingEvents.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if ((ss.type).toLowerCase().includes(query.toLowerCase()) || (ss.description).toLowerCase().includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) > 0 ? Math.ceil(findTrackingEvents.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if ((ss.type).toLowerCase().includes(query.toLowerCase()) || (ss.description).toLowerCase().includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) : 1)].map((x, i) => (
                                                <span className={(pageNumber === (i + 1)) ? 'pageNumber selected' : ((((pageNumber - (i + 1)) <= 3) && ((pageNumber - (i + 1)) >= -3)) ? 'pageNumber' : 'pageNumber hide')} onClick={() => { setPageNumber(i + 1) }}>{(i + 1)}</span>
                                            ))
                                        }</span><span className="pageNumber" onClick={() => {
                                            setPageNumber(Array(Math.ceil(findTrackingEvents.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if ((ss.type).toLowerCase().includes(query.toLowerCase()) || (ss.description).toLowerCase().includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) > 0 ? Math.ceil(findTrackingEvents.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if ((ss.type).toLowerCase().includes(query.toLowerCase()) || (ss.description).toLowerCase().includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) : 1).length)
                                        }}>&gt;</span>
                                    </div>
                                    <div className="pagelimit">
                                        <div className="pageperpage">Per Page Limit: </div>
                                        <select onChange={(e) => { setPerLimit(parseInt(e.target.value)); setPageNumber(1); }} className="pageperpage">
                                            <option value="20">20</option>
                                            <option value="40">40</option>
                                            <option value="60">60</option>
                                            <option value="80">80</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </strong>
                    <footer>Events</footer>
                </div>
            </div>
        </>
    )
}