import { useState, useEffect } from "react";
import "./../App.css";

import moment from 'moment'

import axios from "axios";
import { useOutletContext } from "react-router-dom";

import { flash } from "./flash";

const localurl = "https://crm.clutchmonkeys.ca";  // Set the Local Domain. TODO: Add to config
const domainurl = "https://crm.clutchmonkeys.ca";  // Set the Root Domain. TODO: Add to config

export let Backup = () => {
    const outlet = useOutletContext() as any;
    const selectedSite = outlet.selectedSite;
    const sites = outlet.sites;

    const [backups, setBackups] = useState([] as any[]);
    const [lock, setLock] = useState(false);

    const [perLimit, setPerLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [error500, setError500] = useState(false);

    const [BBB, setBBB] = useState({ raw: "[]" });
    const [BBBB, setBBBB] = useState([] as any[]);

    const [showBackupRestoreConfirm, setShowBackupRestoreConfirm] = useState(false);
    const [query, setQuery] = useState("");

    const [flashError, setFlashError] = useState(false);
    const [flashWarning, setFlashWarning] = useState(false);
    const [flashMessage, setFlashMessage] = useState('');

    let callBackupsAPI = (token: string, controller: AbortController) => {
        setTimeout(() => {
            const instance = axios.create({
                signal: controller.signal,
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            instance
                .post(domainurl + "/backups")
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Start Check for Errors */

                    setBackups(response.data.backups.reverse());
                    setLock(response.data.lock);
                    setLoading2(false);

                    setTimeout(() => {
                        if (response.data.lock) {
                            const controller2 = new AbortController();
                            callBackupsAPI("false", controller2);
                        }
                    }, 3000);
                })
                .catch((error) => {
                })
                .finally(() => { });
        }, 0);
    }

    let showBackupRestoreModel = (bb: any) => {
        setBBB(bb);
        let queue = [] as any[];
        let rrr = (bbbb: any, d: any) => {
            if (!bbbb || bbbb.length === 0) {
                return;
            }
            for (var j = 0; j < bbbb.length; j++) {
                let node = bbbb[j];
                queue.push({ node: node, d: d });
                rrr(node.next, d + 1);
            }
        }
        rrr(JSON.parse(bb.raw), 0);
        setBBBB(queue);
        setShowBackupRestoreConfirm(true);
    }
    let hideBackupRestoreModel = () => {
        setBBB({ raw: "[]" });
        setShowBackupRestoreConfirm(false);
    }
    let restoreGoogleDrive = (bb: any) => {
        callRestoreSiteAPI('token', bb, 'GoogleDrive');
    }
    let restoreSFTP = (bb: any) => {
        callRestoreSiteAPI('token', bb, 'SFTP');
        setTimeout(() => {
            hideBackupRestoreModel();
        }, 1000);
    }

    let callRestoreSiteAPI = (token: string, bb: any, type: string) => {
        setTimeout(() => {
            setLock(true);
            const instance = axios.create({
                withCredentials: true
            });

            // Set the AUTH token for any request
            instance.interceptors.request.use((config) => {
                config.headers!.Authorization = token ? `Bearer ${token}` : "";
                return config;
            });

            let all = (document.getElementById("backup-all-0") as HTMLInputElement)!.checked;

            let bbbs = BBBB.map((r: any, i: any) => {
                let cc = (document.getElementById(r.node.name + i) as HTMLInputElement)!.checked;
                return cc ? r.node.value : 0;
            });

            hideBackupRestoreModel();

            instance
                .post(domainurl + "/restoreSite", { id: bb._id, type: type, all: all, bbb: bbbs })
                .then((response) => {
                    /* Start Check for Errors */
                    let ttoken = localStorage.getItem("token");
                    if (!ttoken || ttoken === 'null') {
                        return;
                    }
                    if (response.data.status === 501) {
                        setError500(true);
                        return true;
                    }
                    setLock(false);
                    if (flash(response, setFlashError, setFlashWarning, setFlashMessage)) return;
                    /* End Check for Errors */
                })
                .catch((error) => { })
                .finally(() => { });
        }, 0)
    }

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const backupsC = () => {
        const controller = new AbortController();
        callBackupsAPI("false", controller);
    }

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        setLoading2(true);
        callBackupsAPI("false", controller);
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <>
            {
                flashError
                    ? <div className="flash flash-error">{flashMessage}</div>
                    : <></>}
            {
                flashWarning
                    ? <div className="flash flash-warning">{flashMessage}</div>
                    : <></>}
            <div className="three three-report">
                <div className="scroll2">
                    <div className="report-all">
                        <div className="splash">Backups</div>
                        <div onClick={backupsC} className="refresh">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bootstrap-reboot" viewBox="0 0 16 16">
                                <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                                <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                            </svg>
                        </div>
                    </div>
                    <strong>
                        <div>
                            <div className="Sites">
                                <div className="Sites-Header">
                                    <div>
                                        <div className="Sites-Cell">
                                            Site Name
                                        </div>
                                        <div className="Sites-Cell">
                                            Sites URL
                                        </div>
                                        <div className="Sites-Cell">
                                            Backup Dates & Time
                                        </div>
                                        <div className="Sites-Cell">
                                            Progress
                                        </div>
                                        <div className="Sites-Cell">
                                            Actions
                                        </div>
                                    </div>
                                </div>
                                <span className=""><input onChange={event => setQuery(event.target.value)} className="SearchBarSites BackupSearch" type="text" placeholder="Search Backups ..." /></span>
                                {error500 ?
                                    <div>
                                        <div className="modelMainError">
                                            <div className="modelMainErrorContainer">
                                                An internal server error has been captured. A Technician can contact you shortly. Email <a href="mailto:support@tjmicro.com">support@tjmicro.com</a>.
                                                <button onClick={() => { setError500(false); }}>Okay</button>
                                            </div>
                                        </div>
                                    </div> : <></>}
                                {
                                    showBackupRestoreConfirm ?
                                        <div className="model22 modelbackup">
                                            <div className="modelConfirm modelConfirmThree bb">
                                                <div>Are you sure you want to restore backup?</div>
                                                <div className="bbb-all"><input id="backup-all-0" type="checkbox" defaultChecked={true} onChange={(e) => { BBBB.map((r: any, i: any) => { if (document.getElementById(r.name + i) as HTMLInputElement) { (document.getElementById(r.name + i) as HTMLInputElement)!.checked = e.target.checked } }) }} /><label htmlFor="backup-all-0"><span className="bbb-col bbb-all">Selected will replace the entire Drive.</span><span className="bbb-all"></span></label></div>
                                                <div>{
                                                    BBBB.map((r: any, i: any) => (
                                                        <div style={{ marginLeft: (r.d * 10) + 'px' }} className="bbb-all"><span className="cursor">{r.node.folder ? "+" : "."}</span><input id={r.node.name + i} type="checkbox" defaultChecked={true} /><label htmlFor={r.node.name + i}><span className="bbb-col bbb-all">{r.node.name}</span><span className="bbb-all">{r.node.folder ? ("Folder (" + r.node.folder['childCount'] + " items)") : r.node.last}</span></label></div>
                                                    ))
                                                }</div>
                                                <button onClick={() => { restoreGoogleDrive(BBB) }}>Google Drive</button>
                                                <button className="ahref" onClick={() => { restoreSFTP(BBB) }}>SFTP Server</button>
                                                <button className="ahref" onClick={() => { hideBackupRestoreModel() }}>Cancel</button>
                                            </div>
                                        </div> : <></>
                                }
                                <div>{
                                    ((backups.filter((ss: any) => {
                                        if (query === '') {
                                            return ss;
                                        } else if (((ss.type !== 'users') ? ss.displayName : 'OneDrive').toLowerCase().includes(query.toLowerCase()) || ss.siteUrl.toLowerCase().includes(query.toLowerCase()) || moment(ss.date).format('l LT').includes(query.toLowerCase())) {
                                            return ss;
                                        }
                                        return false;
                                    }).length > 0) ?
                                        backups.filter((ss: any) => {
                                            if (query === '') {
                                                return ss;
                                            } else if (((ss.type !== 'users') ? ss.displayName : 'OneDrive').toLowerCase().includes(query.toLowerCase()) || ss.siteUrl.toLowerCase().includes(query.toLowerCase()) || moment(ss.date).format('l LT').includes(query.toLowerCase())) {
                                                return ss;
                                            }
                                            return false;
                                        }).slice((pageNumber - 1) * perLimit, pageNumber * perLimit).map((bb, i) => (
                                            <div key={i} className="Sites-Body">
                                                <div className="Sites-Cell">{(bb.type !== 'users') ? bb.displayName : 'OneDrive'}</div>
                                                <div className="Sites-Cell">{bb.siteUrl}</div>
                                                <div className="Sites-Cell">{moment(bb.date).format('l LT')}</div>
                                                <div className="Sites-Cell">{ (parseFloat(bb.progress) * 100).toFixed(2) + "%" }</div>
                                                <div className="Sites-Cell">
                                                    <button onClick={() => { if ((lock !== true)) { showBackupRestoreModel(bb); } }}>{(lock !== true) ? "Restore" : "Restore"}</button>
                                                </div>
                                            </div>
                                        )) :
                                    <span>No Backups to Show.</span>)
                                }</div>
                                <div className="paginate-scroll fs">
                                    <div className="page pageNumberC">
                                        <span className="pageNumber" onClick={() => { setPageNumber(1) }}>&lt;</span><span>{
                                            [...Array(Math.ceil(backups.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if (((ss.type !== 'users') ? ss.displayName : 'OneDrive').toLowerCase().includes(query.toLowerCase()) || ss.siteUrl.toLowerCase().includes(query.toLowerCase()) || moment(ss.date).format('l LT').includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) > 0 ? Math.ceil(backups.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if (((ss.type !== 'users') ? ss.displayName : 'OneDrive').toLowerCase().includes(query.toLowerCase()) || ss.siteUrl.toLowerCase().includes(query.toLowerCase()) || moment(ss.date).format('l LT').includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) : 1)].map((x, i) => (
                                                <span className={(pageNumber === (i + 1)) ? 'pageNumber selected' : ((((pageNumber - (i + 1)) <= 3) && ((pageNumber - (i + 1)) >= -3)) ? 'pageNumber' : 'pageNumber hide')} onClick={() => { setPageNumber(i + 1) }}>{(i + 1)}</span>
                                            ))
                                        }</span><span className="pageNumber" onClick={() => {
                                            setPageNumber(Array(Math.ceil(backups.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if (((ss.type !== 'users') ? ss.displayName : 'OneDrive').toLowerCase().includes(query.toLowerCase()) || ss.siteUrl.toLowerCase().includes(query.toLowerCase()) || moment(ss.date).format('l LT').includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) > 0 ? Math.ceil(backups.filter((ss: any) => {
                                                if (query === '') {
                                                    return ss;
                                                } else if (((ss.type !== 'users') ? ss.displayName : 'OneDrive').toLowerCase().includes(query.toLowerCase()) || ss.siteUrl.toLowerCase().includes(query.toLowerCase()) || moment(ss.date).format('l LT').includes(query.toLowerCase())) {
                                                    return ss;
                                                }
                                            }).length / perLimit) : 1).length)
                                        }}>&gt;</span>
                                    </div>
                                    <div className="pagelimit">
                                        <div className="pageperpage">Per Page Limit: </div>
                                        <select onChange={(e) => { setPerLimit(parseInt(e.target.value)); setPageNumber(1); }} className="pageperpage">
                                            <option value="20">20</option>
                                            <option value="40">40</option>
                                            <option value="60">60</option>
                                            <option value="80">80</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </strong>
                    <footer>Backups</footer>
                </div>
            </div>
        </>
    )
}